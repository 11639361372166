import { inRange } from "lodash";
import React, { useEffect, useState } from "react";

export const DeviceContext = React.createContext();

const DeviceContextProvider = ({ children }) => {
  const [device, setDevice] = useState("desktop");

  useEffect(() => {
    const handleResize = () => {
      if (inRange(window.screen.width, 0, 768)) {
        setDevice("mobile");
      } else if (inRange(window.screen.width, 768, 1025)) {
        setDevice("mobile");
      } else {
        setDevice("desktop");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <DeviceContext.Provider value={{ device }}>
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceContextProvider;

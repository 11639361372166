import {
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FieldArray, FormikProvider } from "formik";
import _, { cloneDeep, isString, size, toString } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useEffect, useRef, useState } from "react";
import { handleSubmit } from "./CommonMethode";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./index.scss";
import { Slider } from "rsuite";
import "rsuite/dist/rsuite.css";
import AxiosInstance from "../AxiosRequest";
// import rangesliderJs from "rangeslider-js";

// For the first time this function will take all the formik values after that once user click on the save as draft button then it will take state values.
export default function UploadContent({
  formik,
  state,
  setState,
  imageFile,
  setImageFile,
  subMenuIndex,
  contentMenu,
}) {
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [nftDetails, setNftDetail] = useState({});
  const [nftstate, setNFTState] = useState({
    nftImages: [],
    nftDetail: null,
  });
  const [isPreviewModalshow, setIsPreviewModalShow] = useState(false);
  const [isShowNFTDetail, setIsShowNFTDetails] = useState([true]);
  const [isShowNFTDetailModal, setIsShowNFTDetailsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [isShowCropImageModal, setIsShowCropImageModal] = useState(false);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [image, setImage] = useState(null);
  const [cropImages, setCropImages] = useState([[]]);
  const [imageIndex, setImageIndex] = useState(0);
  const [layerIndex, setLayerIndex] = useState(0);
  const [collapsed, setIsCollapsed] = useState([true]);
  const [layerCollapsed, setIsLayerCollapsed] = useState(false);
  const [shuffleIndex, setShuffleIndex] = useState(0);
  const [isShuffle, setIsShuffle] = useState(false);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  const containerRef = useRef(null);
  const [showPreviewImgModal, setPreviewImgModal] = useState(false);
  const [imageZoom, setImageZoom] = useState();
  const [collection, setCollection] = useState();
  const [imageArray, setImageArray] = useState();
  const [layerNameChange, setLayerNameChange] = useState(false);

  const getData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getData?id=${state.collection._id}`
    );
    if (response.status === 200) {
      if (response.data && response.data.data) {
        setState({
          ...state,
          collection: response.data.data,
        });
        setCollection(response.data.data);
      }
    }
  };

  useEffect(() => {
    if (state.collection?._id) {
      getData();
    }
  }, [state.collection?._id, state.currentStep, subMenuIndex]);

  useEffect(() => {
    console.log(state.collection)
    if (
      state.collection?._id &&
      (state.collection?.layers?.[0]?.images?.length > 0 || state.collection?.layers?.[0]?.attributes?.length > 0)
    ) {
      getImages();
    }
  }, [
    state.collection?.stage,
    state.collection?.layers?.[layerIndex]?.images?.length,
    state.currentStep,
    subMenuIndex,
  ]);

  //This funciton is called when user has already uploades some images and after that user will came back to show that images then this will be called.
  const getImages = async () => {
    console.log("hello")
    let array =
      collection?.layers?.[layerIndex]?.images?.length ===
      state.collection?.layers?.[layerIndex]?.images?.length
        ? imageFile
        : [];
    for (let i = 0; i < state.collection?.layers.length; i++) {
      if (state.collection?.layers?.[i]?.images?.length > 0 || state.collection?.layers?.[i]?.attributes?.length > 0) {
        for (let j = 0; j < state.collection.layers[i]?.images.length; j++) {
          if (!state.collection.layers[i]?.images[j]?.image_file) {
            let file = await urlToObject(i, j);
            array.push(file);
          }
        }
      }
    }
    setImageFile(array);
  };

  const urlToObject = async (i, j) => {
    const background_color =
      state.collection?.layers?.[i]?.attributes?.[j]?.background_color;
    const rarity = state.collection?.layers?.[i]?.attributes?.[j]?.rarity;
    const layerName = state.collection?.layers?.[i]?.layerName
      ? state.collection?.layers?.[i]?.layerName
      : formik.values?.layers?.[i]?.layerName;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${encodeURIComponent(
        `${state.folderName}/layers/${layerName}/${background_color}#${rarity}.png`
      )}`
    );
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });
    setState({
      ...state,
      collection: {
        ...state.collection,
        ...(state.collection.layers[i].images[j] = file),
      },
    });
    return file;
  };

  useEffect(() => {
    handleShufflePreview();
  }, [
    // formik.values,
    // formik.values?.layers?.length,
    // formik.values?.layers?.[layerIndex],
    // layerIndex,
    // formik.values?.layers?.[layerIndex]?.images[0]?.image_url,
    formik.collection?.layers,
    state.collection?.layers,
    formik.values?.layers?.[layerIndex]?.images[imageIndex]?.background_color,
    formik.values?.layers?.[layerIndex]?.images[imageIndex]?.rarity,
    state.collection?.layers?.[layerIndex]?.images?.length,
    state.currentStep,
    imageFile,
    layerIndex,
  ]);

  const handlePreviewNFT = async () => {
    try {
      if (
        (formik.values.layers?.[1]?.images?.length > 0 &&
          formik.values?.layers?.[1]?.images?.[0]?.background_color &&
          formik.values?.layers?.[1]?.images?.[0]?.rarity) ||
        (state.collection?.layers?.[1]?.attributes?.[0]?.background_color &&
          state.collection?.layers?.[1]?.attributes?.[0]?.rarity > 0 &&
          state.collection?.layers?.[1]?.images?.length > 0 &&
          state.collection.layers[1].images?.length > 0) ||
        (state.collection.layers?.[layerIndex]?.attributes?.[0]
          ?.background_color &&
          state.collection.layers?.[layerIndex]?.attributes?.[0]?.rarity) ||
        (state.collection.layers?.[layerIndex]?.images?.[0]?.background_color &&
          state.collection.layers?.[layerIndex]?.images?.[0]?.rarity)
      ) {
        setIsLoading(true);
        let layers = [];
        if (state.collection.layers?.[0]?.images?.length > 0) {
          for (let i = 0; i < state.collection.layers.length; i++) {
            for (let j = 0; j < state.collection.layers[i].images.length; j++) {
              if (state.collection.layers?.[i]?.images?.[j]?.name) {
                const file = state.collection.layers[i].images[j];
                const ext = file?.type.split("/");
                state.collection.layers[i].images[j].image_file = file;
                state.collection.layers[i].images[
                  j
                ].image_name = `${state.collection.layers[i].attributes[j].background_color}#${state.collection.layers[i].attributes[j].rarity}_${state.collection.layers[i].layerName}.${ext[1]}`;
              } else {
                const file = state.collection.layers[i].images[j].image_file;
                const ext = file?.type.split("/");
                // state.collection.layers[i].images[j].image_file = file;
                state.collection.layers[i].images[
                  j
                ].image_name = `${state.collection.layers[i].images[j].background_color}#${state.collection.layers[i].images[j].rarity}_${state.collection.layers[i].layerName}.${ext[1]}`;
              }
            }
          }
          layers = state.collection.layers;
        } else {
          layers = formik.values.layers;
        }
        const values = {
          layers: layers,
          noOfNfts: formik.values.noOfNfts
            ? formik.values.noOfNfts
            : state.collection.no_of_nfts,
          projectName: state.collection?.collection_name
            ? state.collection.collection_name
            : formik.values.projectName,
          description: formik.values.description,
          nftSymbol: state.collection?.nft_handle
            ? state.collection.nft_handle
            : formik.values.nftSymbol,
        };

        if (
          values.layers?.[layerIndex]?.images[0]?.image_file ||
          values.layers?.[layerIndex]?.images[0]?.name
        ) {
          const getNFTS = await handleSubmit(values);
          setNFTState({
            ...nftstate,
            nftImages: getNFTS,
          });
          setIsPreviewModalShow(true);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        setNFTState({});
      }
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const handleShufflePreview = async () => {
    // It will call after user added atleast 2 layers and in each layer there is atleast one image , rarity and it's background color
    if (
      (formik.values.layers?.[1]?.images?.length > 0 &&
        formik.values?.layers?.[1]?.images?.[0]?.background_color &&
        formik.values?.layers?.[1]?.images?.[0]?.rarity) ||
      (state.collection?.layers?.[1]?.attributes?.[0]?.background_color &&
        state.collection?.layers?.[1]?.attributes?.[0]?.rarity > 0 &&
        state.collection?.layers?.[1]?.images?.length > 0 &&
        state.collection.layers[1].images?.length > 0) ||
      (state.collection.layers?.[layerIndex]?.attributes?.[imageIndex]
        ?.background_color &&
        state.collection.layers?.[layerIndex]?.attributes?.[imageIndex]
          ?.rarity) ||
      (state.collection.layers?.[layerIndex]?.images?.[imageIndex]
        ?.background_color &&
        state.collection.layers?.[layerIndex]?.images?.[imageIndex]?.rarity)
    ) {
      setIsShuffle(true);
      let layers = [];
      //It will check if user has already added some layers or not
      if (state.collection.layers?.[0]?.images?.length > 0) {
        for (let i = 0; i < state.collection.layers.length; i++) {
          for (let j = 0; j < state.collection.layers[i].images.length; j++) {
            if (state.collection.layers?.[i]?.images?.[j]?.name) {
              const file = state.collection.layers[i].images[j];
              const ext = file?.type.split("/");
              state.collection.layers[i].images[j].image_file = file;
              state.collection.layers[i].images[
                j
              ].image_name = `${state.collection.layers[i].attributes[j].background_color}#${state.collection.layers[i].attributes[j].rarity}_${state.collection.layers[i].layerName}.${ext[1]}`;
            } else {
              // const file = state.collection.layers[i].images[j].image_file;
              // console.log(file)
              // const ext = file?.type.split("/");
              // state.collection.layers[i].images[j].image_file = file;
              // state.collection.layers[i].images[
              //   j
              // ].image_name = `${state.collection.layers[i].images[j].background_color}#${state.collection.layers[i].images[j].rarity}_${state.collection.layers[i].layerName}.${ext[1]}`;
            }
          }
        }
        layers = state.collection.layers;
      } else {
        layers = formik.values.layers;
      }
      const values = {
        layers: layers,
        noOfNfts: formik.values.noOfNfts
          ? formik.values.noOfNfts
          : state.collection.no_of_nfts,
        projectName: state.collection?.collection_name
          ? state.collection.collection_name
          : formik.values.projectName,
        description: formik.values.description,
        nftSymbol: state.collection?.nft_handle
          ? state.collection.nft_handle
          : formik.values.nftSymbol,
      };
      if (
        values.layers?.[layerIndex]?.images[0]?.image_file ||
        values.layers?.[layerIndex]?.images[0]?.name
      ) {
        const getNFTS = await handleSubmit(values);
        setNFTState({
          ...nftstate,
          nftImages: getNFTS,
        });
        let count = shuffleIndex;
        if (getNFTS.length > 0) {
          if (count >= getNFTS.length - 1) {
            setShuffleIndex(0);
          } else {
            count++;
            setShuffleIndex(count);
          }
        }
        setIsShuffle(false);
      }
    } else {
      setIsShuffle(false);
      setNFTState({});
    }
  };

  // It will called when user uploads layer wise images
  const onSelectFile = (e, imageArray, index) => {
    // let array = cropImages;
    // if (layerIndex > 0) {
    //   array[layerIndex] = [[]];
    // }
    let array = state.collection?.layers?.[layerIndex]?.images;
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const ext = file.type.split("/");
        if (
          ext[1] === "png" ||
          ext[1] === "jpg" ||
          ext[1] === "jpeg" ||
          ext[1] === "webp"
        ) {
          setErrorMessage("");
          // Object.defineProperty(file, "name", {
          //   value: `${formik.values.layers[index].layerName}_${count}.${ext[1]}`,
          // });
          const timestamp = new Date().getTime();
          if (state.collection?.layers?.[0]?.attributes?.length > 0) {
            array.push({
              image_name: file.name,
              image_file: file,
              image_url: URL.createObjectURL(e.target.files[i]),
            });
          } else {
            imageArray.push({
              image_name: file.name,
              image_file: file,
              image_url: URL.createObjectURL(e.target.files[i]),
            });
            // array[layerIndex][count] = URL.createObjectURL(e.target.files[i]);
          }
        } else {
          setErrorMessage("Only accept image");
        }
      }
      if (fileInputRef && fileInputRef.current && fileInputRef.current.value) {
        fileInputRef.current.value = "";
      }
    }
    if (state.collection?.layers?.[0]?.attributes?.length > 0) {
      setState({
        ...state,
        collection: {
          ...state.collection,
          ...(state.collection.layers[layerIndex].images = array),
        },
      });
      setCollection({
        ...collection,
        ...(collection.layers[layerIndex].images = array),
      });
    }
    imageItem(imageArray, index);
    // setCropImages([...cropImages, ...array]);
  };

  //It will called when user changes bbackground color
  const handleBackgroundImageChange = (parentIndex, e, imageArray, index) => {
    setLayerNameChange(false);
    const regex = /[~`!@_#$%^&*()+={}\[\];:\'\"<>.,\/\\\?]/;
    let color = {};
    if (e.target.value === "") {
      imageArray["background_color"] = "";
    }

    if (
      index > 0 &&
      e.target.value ==
        formik.values?.layers[layerIndex]?.images?.[index - 1]?.background_color
    ) {
      // if (state.collection.layers[layerIndex]?.attributes?.length > 0) {
      //   setState({
      //     ...state,
      //     collection: {
      //       ...state.collection,
      //       ...(state.collection.layers[layerIndex].attributes[
      //         index
      //       ].background_color = e.target.value),
      //     },
      //   });
      // } else {
      // imageArray["background_color"] = e.target.value;
      // // }
      // setValidationError("Please add different name");
      setValidationError("Please add different name");
      imageArray["background_color"] = e.target.value;
    } else {
      setValidationError("");
      if (regex.test(e.target.value)) {
        if (state.collection.layers[parentIndex]?.images?.length > 0) {
          setState({
            ...state,
            collection: {
              ...state.collection,
              ...(state.collection.layers[parentIndex].images[
                index
              ].background_color = e.target.value),
            },
          });
        } else if (
          state.collection.layers[parentIndex]?.attributes?.length > 0
        ) {
          setState({
            ...state,
            collection: {
              ...state.collection,
              ...(state.collection.layers[parentIndex].attributes[
                index
              ].background_color = e.target.value),
            },
          });
        } else {
          imageArray["background_color"] = e.target.value;
        }
        setValidationError("Please do not add any special characters");
      } else {
        if (state.collection.layers[parentIndex]?.images?.length > 0) {
          setState({
            ...state,
            collection: {
              ...state.collection,
              ...(state.collection.layers[parentIndex].images[
                index
              ].background_color = e.target.value),
            },
          });
        } else if (
          state.collection.layers[parentIndex]?.attributes?.length > 0
        ) {
          setState({
            ...state,
            collection: {
              ...state.collection,
              ...(state.collection.layers[parentIndex].attributes[
                index
              ].background_color = e.target.value),
            },
          });
        } else {
          imageArray["background_color"] = e.target.value;
        }
        setValidationError("");
      }
    }
    imageItem(imageArray, index, color.background_color);
    setImageArray(color);
  };

  //It will called when user changes rarity
  const handleRarityChange = (parentIndex, value, imageArray, index) => {
    let color = {};
    if (value === 0) {
      imageArray["rarity"] = 0;
    }
    color.rarity = value;
    if (state.collection?.layers[parentIndex]?.attributes?.length > 0) {
      setState({
        ...state,
        collection: {
          ...state.collection,
          ...(state.collection.layers[parentIndex].attributes[index].rarity =
            value),
        },
      });
    } else {
      imageArray["rarity"] = value;
    }
    imageItem(imageArray, index, color.rarity);
    setImageArray(color);
  };

  //It will called when user remove image
  const removeImage = (layerIndex, imageIndex) => {
    const removedImages = cloneDeep(
      state.collection?.layers?.[layerIndex]?.images?.length > 0
        ? state.collection.layers[layerIndex]
        : formik.values.layers[layerIndex]
    );
    if (removedImages?.attributes?.length > 0) {
      removedImages.attributes.splice(imageIndex, 1);
    }
    removedImages.images.splice(imageIndex, 1);
    cropImages.splice(imageIndex, 1);
    let cloneLayers =
      state.collection.layers[layerIndex]?.images?.length > 0
        ? state.collection.layers
        : formik.values.layers;
    cloneLayers[layerIndex].images = removedImages.images;
    if (cloneLayers[layerIndex]?.attributes?.length > 0) {
      if (
        cloneLayers.length > 0 &&
        cloneLayers[layerIndex].attributes?.[imageIndex]
      ) {
        for (let i = 0; i < cloneLayers[layerIndex].images.length; i++) {
          cloneLayers[layerIndex].attributes[i].background_color = `${
            cloneLayers[layerIndex].layerName
          }${i + 1}`;
        }
      }
    } else {
      if (
        cloneLayers.length > 0 &&
        cloneLayers[layerIndex].images?.[imageIndex]
      ) {
        for (let i = 0; i < cloneLayers[layerIndex].images.length; i++) {
          cloneLayers[layerIndex].images[i].background_color = `${
            cloneLayers[layerIndex].layerName
          }${i + 1}`;
        }
      }
    }
    // if (cloneLayers?.[layerIndex]?.attributes?.length > 0) {
    //   cloneLayers[layerIndex].attributes = removedImages.attributes;
    // }
    if (state.collection.layers?.[layerIndex]?.images?.length > 0) {
      setState({
        ...state,
        collection: {
          ...state.collection,
          ...(state.collection.layers[layerIndex].images =
            cloneLayers[layerIndex].images),
          ...(state.collection.layers[layerIndex].attributes =
            cloneLayers[layerIndex].attributes),
        },
      });
    }
    fileInputRef.current.value = "";
    formik.setFieldValue({ removedImages });
  };

  //It will called when user remove layer
  const handleRemoveLayer = (layerIndex) => {
    setLayerIndex(layerIndex - 1);
    const removedLayer = cloneDeep(
      state.collection?.layers?.[layerIndex]?.images?.length > 0
        ? state.collection.layers[layerIndex]
        : formik.values.layers[layerIndex]
    );
    if (state.collection?.layers?.[layerIndex]?.images?.length > 0) {
      state.collection.layers.splice(layerIndex, 1);
    }
    formik.values.layers.splice(layerIndex, 1);
    setIsInputOpen(false);
    formik.setFieldValue({ removedLayer });
  };

  //It will called when user add layer
  const addLayer = (layerArray) => {
    let array = {};
    if (state.collection?.layers?.[0]?.images?.length === 0) {
      if (
        formik.values.layers.length > 0 &&
        formik.values.newLayer?.length > 0
      ) {
        for (let i = 0; i < formik.values.layers.length; i++) {
          if (formik.values.newLayer === formik.values.layers[i].layerName) {
            formik.setFieldError(
              `newLayer`,
              "Please enter different trait name."
            );
            return;
          }
        }
      } else if (formik.values.newLayer === "") {
        formik.setFieldError(`newLayer`, "Please enter trait name.");
        return;
      }
    } else {
      if (
        state.collection?.layers?.length > 0 &&
        formik.values.newLayer?.length > 0
      ) {
        for (let i = 0; i < state.collection.layers.length; i++) {
          if (formik.values.newLayer === state.collection.layers[i].layerName) {
            formik.setFieldError(
              `newLayer`,
              "Please enter different trait name."
            );
            return;
          }
        }
      } else if (formik.values.newLayer === "") {
        formik.setFieldError(`newLayer`, "Please enter trait name.");
        return;
      }
    }
    if (state.collection?.layers?.[0]?.images?.length > 0) {
      array.layerName = formik.values.newLayer;
      array.images = [];
      setState({
        ...state,
        collection: {
          ...state.collection,
          layers: [...state.collection.layers, array],
        },
      });
      setCollection({
        ...collection,
        layers: [...state.collection.layers, array],
      });
      setCollapsedIndex(state.collection.layers.length);
      setLayerIndex(state.collection.layers.length);
    } else {
      layerArray.push({
        layerName: formik.values.newLayer,
        images: [],
      });
      setCollapsedIndex(formik.values.layers.length);
      setLayerIndex(formik.values.layers.length);
    }
    setImageIndex(0);
    formik.setFieldValue("newLayer", "");
    if (formik.values.layerName) {
      formik.setFieldValue("layerName", "");
    }
    setIsLayerCollapsed(!layerCollapsed);
  };

  const setCollapsedIndex = (index) => {
    let array = collapsed;
    if(state.collection?.layers?.[0]?.images?.length > 0) {
      if (
        state.collection.layers.length > 1 &&
        index + 1 <= state.collection.layers.length
      ) {
        for (let i = 0; i < state.collection.layers.length; i++) {
          if (index === i) {
            array[index] = !array[index];
          } else {
            array[i] = false;
          }
        }
      } else {
        array[index] = !array[index];
      }
    } else {
      if (
        formik.values.layers.length > 1 &&
        index + 1 <= formik.values.layers.length
      ) {
        for (let i = 0; i < formik.values.layers.length; i++) {
          if (index === i) {
            array[index] = !array[index];
          } else {
            array[i] = false;
          }
        }
      } else {
        array[index] = !array[index];
      }
    }
    
    setIsCollapsed([...array]);
  };

  const handleZoomImg = (e, image) => {
    setPreviewImgModal(true);
    setImageZoom(image);
  };

  //This function is return the layer wise images background color and it's rarity
  const imageItem = (parentIndex) => (image, index) => {
    //This will check whether background color and rarity will be getting from formik values or from state values.
    const background_color = image?.background_color
      ? image?.background_color
      : state.collection?.layers?.[parentIndex]?.images?.[index]
          ?.background_color
      ? state.collection?.layers?.[parentIndex]?.images?.[index]
          ?.background_color
      : state.collection?.layers?.[parentIndex]?.attributes?.[index]
          ?.background_color;
    const rarity = image?.rarity
      ? image?.rarity
      : state.collection?.layers?.[parentIndex]?.images?.[index]?.rarity
      ? state.collection?.layers?.[parentIndex]?.images?.[index]?.rarity
      : state.collection?.layers?.[parentIndex]?.attributes?.[index]?.rarity;
    const layerName = formik.values?.layerName
      ? formik.values?.layerName
      : formik.values?.layers?.[parentIndex]?.layerName
      ? formik.values?.layers?.[parentIndex]?.layerName
      : state.collection?.layers?.[parentIndex]?.layerName;
    //check if there are state values.
    if (
      state.collection?.layers?.[parentIndex]?.images?.length > 0 &&
      state.collection?.layers?.[parentIndex]?.attributes?.[index]
        ?.background_color &&
      state.collection?.layers?.[parentIndex]?.attributes?.[index]?.rarity &&
      imageFile.length > 0
    ) {
      let count = 0;
      let i = parentIndex;
      if (parentIndex > 0) {
        while (i > 0) {
          for (
            let j = 0;
            j < state.collection?.layers[i - 1]?.images.length;
            j++
          ) {
            count++;
          }
          i--;
        }
      }
      const fileIndex = parentIndex === 0 ? index : count + index;
      console.log(fileIndex);
      if (imageFile[fileIndex]) {
        image = imageFile[fileIndex];
        image.image_file = imageFile[fileIndex];
        image.image_url = URL.createObjectURL(imageFile[fileIndex]);
        const file = image?.image_file;
        const ext = file?.type.split("/");
        image.image_name = `${background_color}#${rarity}_${layerName}.${ext[1]}`;
      }
    } else {
      const file = image?.image_file;
      const ext = file?.type.split("/");
      if (file && image?.background_color && image?.rarity) {
        image.image_name = `${background_color}#${rarity}_${layerName}.${ext[1]}`;
      }
    }

    //Write a script for by default background name will be layername + index
    if (!image.background_color && !background_color) {
      if (state.collection?.layers?.[parentIndex]?.layerName) {
        image.background_color =
          !layerNameChange && background_color
            ? background_color
            : `${state.collection?.layers?.[parentIndex]?.layerName}${
                index + 1
              }`;
        image.background_color =
          !layerNameChange && background_color
            ? background_color
            : `${state.collection?.layers?.[parentIndex]?.layerName}${
                index + 1
              }`;
      } else {
        image.background_color =
          !layerNameChange && background_color
            ? background_color
            : `${formik.values?.layers?.[parentIndex]?.layerName}${index + 1}`;
        image.background_color =
          !layerNameChange && background_color
            ? background_color
            : `${formik.values?.layers?.[parentIndex]?.layerName}${index + 1}`;
      }
    }

    ////Write a script for by default rarity will be 100
    if (!image.rarity && !rarity) {
      if (state.collection?.layers?.[parentIndex]?.layerName) {
        image.rarity = rarity ? rarity : 100;
        image.rarity = rarity ? rarity : 100;
      } else {
        image.rarity = rarity ? rarity : 100;
        image.rarity = rarity ? rarity : 100;
      }
    }

    return (
      <Col sm={12} md={12} xl={12}>
        <div className="mt-3 mb-3 br"></div>
        <div className="d-flex">
          <OverlayTrigger
            key={"top"}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`} placement="left">
                Remove
              </Tooltip>
            }
            className="w-100"
          >
            <span
              className="clear-icon"
              onClick={() => removeImage(parentIndex, index)}
            >
              <svg
                className="removeImage"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="none"
                  strokeWidth="2"
                  d="M7,7 L17,17 M7,17 L17,7"
                ></path>
              </svg>
            </span>
          </OverlayTrigger>
        </div>
        <div className="d-flex">
          <div className="mb-2">
            <img
              src={image?.image_url}
              style={{ border: "1px solid #000000" }}
              alt={""}
              className="layerImg backgroundwhite mb-0"
              onClick={(e) => handleZoomImg(e, image?.image_url)}
            />
          </div>
          <div className="mb-3">
            <div>
              <input
                type="text"
                onChange={(e) =>
                  handleBackgroundImageChange(parentIndex, e, image, index)
                }
                value={
                  background_color && !layerNameChange
                    ? background_color
                    : image?.background_color
                }
                className="background-input-text ms-3 mb-1 mt-1 d-flex justify-content-center align-items-center"
                style={{ width: "100%" }}
              />
              {validationError.length > 0 && (
                <span className="text-danger ms-3">{validationError}</span>
              )}
            </div>
            <div
              className="d-flex align-items-center justify-content-between ms-3 mt-2"
              style={{ width: "100%" }}
            >
              <div className="set-rarity mb-2">Set Rarity</div>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="button-tooltip">
                    Defines how often this trait will appear. <br />
                    Recommendations: <br />
                    - Less than 5% = Legendary <br />
                    - Between 5% and 20% = Rare <br />
                    - Between 20 and 60% = Standard <br />- More than 60% =
                    Common
                  </Tooltip>
                }
              >
                <div className="mb-2">
                  <svg
                    id="Group_2600"
                    data-name="Group 2600"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_3248"
                      data-name="Path 3248"
                      d="M0,0H24V24H0Z"
                      fill="none"
                    />
                    <circle
                      id="Ellipse_450"
                      data-name="Ellipse 450"
                      cx="9"
                      cy="9"
                      r="9"
                      transform="translate(3 3)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <line
                      id="Line_141"
                      data-name="Line 141"
                      x2="0.01"
                      transform="translate(12 8)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Path_3249"
                      data-name="Path 3249"
                      d="M11,12h1v4h1"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
              </OverlayTrigger>
            </div>
            <div className="ms-3" style={{ width: "100%" }}>
              <Slider
                // class="range-slider-123"
                progress
                value={rarity ? rarity : image?.rarity}
                onChange={(value) => {
                  handleRarityChange(parentIndex, value, image, index);
                }}
                className="mb-2"
                min={1}
              />
              <div className="rarity-text">
                {rarity ? rarity : image?.rarity}
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    cropImages[layerIndex][imageIndex] = base64Image;
    setIsShowCropImageModal(false);
  };

  //It will called when user edit the layer name
  const handleInput = (index, layer) => {
    setIsInputOpen(true);
    setSelectedItemIndex(index);
    formik.setFieldValue("layerName", layer.layerName);
  };

  const handleSaveInput = (layer, index) => {
    setIsInputOpen(false);
    const newLayerName = formik.values.layerName;
    layer.layerName = newLayerName;
    if (state.collection?.layers?.[index]?.images?.length > 0) {
      for (let i = 0; i < state.collection.layers[index].images.length; i++) {
        if (state.collection.layers[index].images[i]?.name) {
          setState({
            ...state,
            collection: {
              ...state.collection,
              ...(state.collection.layers[index].attributes[
                i
              ].background_color = `${newLayerName}${i + 1}`),
            },
          });
        } else {
          setState({
            ...state,
            collection: {
              ...state.collection,
              ...(state.collection.layers[index].images[
                i
              ].background_color = `${newLayerName}${i + 1}`),
            },
          });
        }
      }
    } else {
      for (let i = 0; i < formik.values.layers[index].images.length; i++) {
        formik.values.layers[index].images[
          i
        ].background_color = `${newLayerName}${i + 1}`;
      }
    }
  };

  //it will call when user uploads any images for particular layer
  const addItem = (index, layer) => (
    <FieldArray
      name={`layers[${index}].images`}
      render={(imageArray) => {
        return (
          <Row>
            {state.collection.layers?.[0]?.images?.length > 0
              ? state.collection.layers?.[index]?.images?.map(imageItem(index))
              : formik.values.layers[index]?.images?.map(imageItem(index))}
            <Col md={12}>
              {size(layer?.images) == 0 ||
              (formik.values.layers[index]?.images?.[size(layer?.images) - 1]
                ?.background_color &&
                formik.values.layers[index]?.images?.[size(layer?.images) - 1]
                  ?.rarity) ||
              state.collection?.layers?.[index]?.images?.length > 0 ||
              (state.collection.layers[index]?.attributes?.[
                state.collection?.layers?.[index]?.images?.length - 1
              ]?.background_color &&
                state.collection.layers[index]?.attributes?.[
                  state.collection?.layers?.[index]?.images?.length - 1
                ]?.rarity) ? (
                <>
                  <div className="mt-1 mb-3 br"></div>
                  <div className="d-flex">
                    <div className="layerImg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-photo"
                        width="60"
                        height="60"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="15" y1="8" x2="15.01" y2="8" />
                        <rect x="4" y="4" width="16" height="16" rx="3" />
                        <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                        <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
                      </svg>
                      <input
                        name={`layers.${index}.images`}
                        type="file"
                        multiple
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                        ref={fileInputRef}
                        onChange={(e) => {
                          onSelectFile(e, imageArray, index, layer);
                        }}
                      />
                    </div>
                    <div className="ms-2 mt-4">
                      <div
                        className="upload-text-details"
                        style={{ opacity: "1" }}
                      >
                        Upload a{" "}
                        {layer && layer.layerName == "Background"
                          ? "background"
                          : layer && layer.layerName}{" "}
                        image {size(layer?.images) > 0 ? "(Optional) ," : ""}
                        PNG, JPEG, TIFF recommended.
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </Col>
            {state.collection?.layers?.[0]?.images?.length > 0
              ? state.collection?.layers &&
                state.collection?.stage === "Traits" &&
                state.collection?.layers?.[index]?.images?.length == 0 && (
                  // formik.values.layers?.[index]?.images?.length == 0 && (
                  <span
                    className="validationText mb-3"
                    style={{ width: "90%" }}
                  >
                    upload atleast 1 image.
                  </span>
                )
              : formik.values.layers &&
                formik.values.layers[index] &&
                formik.values.layers[index].images.length == 0 &&
                state.collection?.layers?.[0]?.images?.length == 0 && (
                  <span
                    className="validationText mb-3"
                    style={{ width: "90%" }}
                  >
                    upload atleast 1 image.
                  </span>
                )}
            {errorMessage.length > 0 && (
              <span className="validationText mb-3">{errorMessage}</span>
            )}
          </Row>
        );
      }}
    />
  );

  //it will call when user add new layer
  const layerItem = (layer, index) => {
    return (
      <Draggable key={index} index={index} draggableId={toString(index)}>
        {(provided) => (
          <div
            key={index}
            className="teamMemberPage"
            ref={provided.innerRef}
            // {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ width: "100%" }}
          >
            <Row className="mb-3">
              <Col md={12} lg={12}>
                {!collapsed[index] ? (
                  <div
                    className="py-3 background-details d-flex justify-content-between"
                    style={{
                      maxHeight: "64px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="d-flex ms-4"
                      onClick={() => {
                        setCollapsedIndex(index);
                        setLayerIndex(index);
                      }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Group_3743"
                          data-name="Group 3743"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            id="Path_3760"
                            data-name="Path 3760"
                            d="M0,0H24V24H0Z"
                            fill="none"
                          />
                          <circle
                            id="Ellipse_682"
                            data-name="Ellipse 682"
                            cx="9"
                            cy="9"
                            r="9"
                            transform="translate(3 3)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                          <line
                            id="Line_389"
                            data-name="Line 389"
                            x2="6"
                            transform="translate(9 12)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                          <line
                            id="Line_390"
                            data-name="Line 390"
                            y2="6"
                            transform="translate(12 9)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                        </svg>
                        <span className="ms-2 title-text">
                          {/* {layer.layerName}{" "} */}
                          {isInputOpen && selectedItemIndex === index ? (
                            <>
                              <>
                                <div className="mb-3">
                                  <Form.Control
                                    type="text"
                                    name={"layerName"}
                                    value={
                                      formik.values.layerName
                                        ? formik.values.layerName
                                        : layer.layerName
                                    }
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                    }}
                                    placeholder="Edit Category Name"
                                    className="form-input-text mb-2"
                                    style={{ width: "95%" }}
                                  />
                                  <Form.Text className="text-details ms-2">
                                    For example: Head, Eyes, Clothes…,
                                  </Form.Text>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="btnGraySM me-4"
                                    style={{ width: "80px" }}
                                    onClick={() => {
                                      handleSaveInput(layer, index);
                                    }}
                                  >
                                    Save
                                  </button>
                                </div>
                              </>
                            </>
                          ) : (
                            layer.layerName
                          )}{" "}
                          {size(layer.images) > 0
                            ? `(${size(layer.images)})`
                            : size(layer.attributes) > 0
                            ? `(${size(layer.attributes)})`
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className="me-4">
                      {(size(layer.images) > 0 || size(layer.attributes) > 0) &&
                      layer.layerName !== "Background" ? (
                        <span
                          // onClick={() => {
                          //   setCollapsedIndex(index);
                          // }}
                          style={{ marginTop: "2px" }}
                          onClick={() => {
                            if (collapsed[index] === false) {
                              setCollapsedIndex(index);
                            }
                            handleInput(index, layer);
                          }}
                          className="ms-4"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-pencil"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1"
                            stroke="#000000"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                          </svg>
                        </span>
                      ) : (
                        ""
                      )}
                      {(size(layer.images) >= 0 ||
                        size(layer.attributes) >= 0) &&
                      layer.layerName !== "Background" ? (
                        <span
                          // onClick={() => {
                          //   setCollapsedIndex(index);
                          // }}
                          style={{ marginTop: "2px" }}
                          onClick={() => {
                            handleRemoveLayer(index);
                          }}
                          className="ms-5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-trash"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#000000"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="4" y1="7" x2="20" y2="7" />
                            <line x1="10" y1="11" x2="10" y2="17" />
                            <line x1="14" y1="11" x2="14" y2="17" />
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                          </svg>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* {addItem(index, layer)} */}
                  </div>
                ) : (
                  <div className="py-3 background-details">
                    {" "}
                    <div className="ms-4">
                      <div className="d-flex justify-content-between">
                        <div
                          className="mb-2"
                          onClick={() => {
                            if (
                              isInputOpen === false &&
                              collapsed[index] === true
                            ) {
                              setCollapsedIndex(index);
                              setLayerIndex(index);
                            }
                          }}
                        >
                          <div className="d-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Group_3743"
                              data-name="Group 3743"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              // onClick={() => {
                              //   setCollapsedIndex(index);
                              // }}
                            >
                              <path
                                id="Path_3760"
                                data-name="Path 3760"
                                d="M0,0H24V24H0Z"
                                fill="none"
                              />
                              <circle
                                id="Ellipse_682"
                                data-name="Ellipse 682"
                                cx="9"
                                cy="9"
                                r="9"
                                transform="translate(3 3)"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                              <line
                                id="Line_389"
                                data-name="Line 389"
                                x2="6"
                                transform="translate(9 12)"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                            </svg>
                            <span className="ms-2 title-text">
                              {/* {layer.layerName}{" "} */}
                              {isInputOpen && selectedItemIndex === index ? (
                                <div>
                                  <div className="mb-3">
                                    <div className="d-flex">
                                      <Form.Control
                                        type="text"
                                        name={"layerName"}
                                        value={
                                          formik.values.layerName
                                            ? formik.values.layerName
                                            : layer.layerName
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                        }}
                                        placeholder="Edit Category Name"
                                        className="form-input-text mb-2"
                                        style={{ width: "95%" }}
                                      />
                                      {size(layer.images) > 0 ? (
                                        <span className="ms-3 mt-1">{`(${size(
                                          layer.images
                                        )})`}</span>
                                      ) : size(layer.attributes) > 0 ? (
                                        <span className="ms-3 mt-1">{`(${size(
                                          layer.attributes
                                        )})`}</span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <Form.Text className="text-details ms-2">
                                      For example: Head, Eyes, Clothes…,
                                    </Form.Text>
                                  </div>
                                </div>
                              ) : (
                                layer.layerName
                              )}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="me-4">
                          {(size(layer.images) > 0 ||
                            size(layer.attributes) > 0) &&
                          layer.layerName !== "Background" ? (
                            <span
                              // onClick={() => {
                              //   setCollapsedIndex(index);
                              // }}
                              style={{ marginTop: "2px" }}
                              onClick={() => {
                                if (collapsed[index] === false) {
                                  setCollapsedIndex(index);
                                }
                                handleInput(index, layer);
                              }}
                              className="ms-4"
                            >
                              {/* <img
                                src={EditIcon}
                                alt=""
                                // onClick={() => {
                                //   handleInput(index);
                                // }}
                              /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-pencil"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke-width="1"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                              </svg>
                            </span>
                          ) : (
                            ""
                          )}
                          {(size(layer.images) >= 0 ||
                            size(layer.attributes) >= 0) &&
                          layer.layerName !== "Background" ? (
                            <span
                              // onClick={() => {
                              //   setCollapsedIndex(index);
                              // }}
                              style={{ marginTop: "2px" }}
                              onClick={() => {
                                handleRemoveLayer(index);
                              }}
                              className={isInputOpen ? "ms-4 me-2" : "ms-5"}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-trash"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <line x1="4" y1="7" x2="20" y2="7" />
                                <line x1="10" y1="11" x2="10" y2="17" />
                                <line x1="14" y1="11" x2="14" y2="17" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                              </svg>
                            </span>
                          ) : (
                            ""
                          )}
                          {isInputOpen && selectedItemIndex === index && (
                            <div className="d-flex justify-content-end mt-3">
                              <button
                                className="btnGraySM"
                                style={{ width: "80px" }}
                                onClick={() => {
                                  handleSaveInput(layer, index);
                                }}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {addItem(index, layer)}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Draggable>
    );
  };

  const handleNFTDetails = (index) => {
    let array = isShowNFTDetail;
    if (nftstate.nftImages?.length > 0) {
      if (index + 1 <= nftstate.nftImages.length) {
        for (let i = 0; i < nftstate.nftImages.length; i++) {
          if (index === i) {
            array[index] = !array[index];
          } else {
            array[i] = false;
          }
        }
      } else {
        array[index] = !array[index];
      }
    }
    setIsShowNFTDetails([...array]);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="grayBox">
            <Row>
              <Col md={8} lg={8}>
                <div className="mb-4">
                  <div
                    className="py-3 background-details"
                    style={{ width: "100%" }}
                  >
                    <div className="ms-4">
                      <div className="d-flex justify-content-between me-4">
                        <div className="d-flex">
                          <span className="ms-2 title-text">
                            {formik.values.newLayer
                              ? formik.values.newLayer
                              : "New Category"}
                          </span>
                        </div>
                      </div>
                      <div className="mt-2">
                        {!formik.values.layers[formik.values?.layers?.length]
                          ?.layerName && (
                          <div className=" d-flex justify-content-between">
                            <div className="col">
                              <Form.Control
                                type="text"
                                name={"newLayer"}
                                value={formik.values.newLayer}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                placeholder="Edit Category Name"
                                className="form-input-text"
                                style={{
                                  width: "95%",
                                }}
                              />
                              <Form.Text className="text-details ms-2">
                                For example: Head, Eyes, Clothes…,
                              </Form.Text>
                            </div>
                            <div className="justify-content-end col-auto">
                              <button
                                className="btnGraySM me-4"
                                style={{
                                  width: "80px",
                                }}
                                disabled={
                                  state.collection?.layers?.[0]?.images
                                    ?.length > 0
                                    ? state.collection?.layers?.[layerIndex]
                                        ?.images?.length === 0
                                    : size(
                                        formik?.values?.layers?.[layerIndex]
                                          ?.images
                                      ) == 0 ||
                                      !formik.values?.layers?.[layerIndex]
                                        ?.images?.[imageIndex]
                                        ?.background_color ||
                                      !formik.values?.layers?.[layerIndex]
                                        ?.images?.[imageIndex]?.rarity
                                }
                                onClick={() => {
                                  addLayer(
                                    state.collection?.layers?.[0]?.images?.length > 0
                                      ? state.collection.layers
                                      : formik.values.layers
                                  );
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      {formik.errors.newLayer &&
                        isString(formik.errors.newLayer) && (
                          <span className="ms-2 validationText">
                            {formik.errors.newLayer}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div
                  style={
                    state.collection?.layers?.length > 1 ||
                    formik.values.layers?.length > 1
                      ? {
                          overflowY: `${collapsed[layerIndex] ? "scroll" : ""}`,
                          overflowX: "hidden",
                          maxHeight: `${contentMenu ? "560px" : "450px"}`,
                        }
                      : {}
                  }
                  className={
                    state.collection?.layers?.length > 1 ||
                    formik.values.layers?.length > 1
                      ? "middle-section mb-5"
                      : ""
                  }
                >
                  <DragDropContext>
                    <FormikProvider value={formik}>
                      <FieldArray
                        name="layers"
                        render={(layerArray) => {
                          return (
                            <>
                              <Row id="add-image">
                                <Col
                                  ref={containerRef}
                                  md={12}
                                  lg={12}
                                  className={
                                    state.collection?.layers?.length > 1 ||
                                    formik.values.layers?.length > 1
                                      ? "addImage"
                                      : ""
                                  }
                                >
                                  <Row className="">
                                    <Droppable
                                      droppableId="layerList"
                                      isDropDisabled={true}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          style={{
                                            backgroundColor:
                                              snapshot.isDraggingOver
                                                ? "#F8F8F8"
                                                : "",
                                          }}
                                          {...provided.droppableProps}
                                        >
                                          <Col md={12} lg={12}>
                                            {state.collection?.layers?.[0]
                                              ?.images?.length > 0
                                              ? state.collection.layers.map(
                                                  layerItem
                                                )
                                              : formik.values.layers.map(
                                                  layerItem
                                                )}
                                            {provided.placeholder}
                                          </Col>
                                        </div>
                                      )}
                                    </Droppable>
                                  </Row>
                                </Col>

                                {formik.errors.layers &&
                                  isString(formik.errors.layers) && (
                                    <Form.Text className="validationText">
                                      {formik.errors.layers}
                                    </Form.Text>
                                  )}
                              </Row>
                            </>
                          );
                        }}
                      />
                    </FormikProvider>
                  </DragDropContext>
                </div>
              </Col>
              <Col md={4} lg={4} className="shuffle-nft">
                <div className="ms-2 mb-4 preview-nft">
                  {formik.values.layers[1]?.images?.length > 0 ||
                  state.collection.layers?.[1]?.attributes?.length > 0 ? (
                    <>
                      {isShuffle ||
                      (formik.values.layers?.[1]?.images?.length === 0 &&
                        state.collection.layers?.[1]?.attributes?.length ===
                          0) ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "335px" }}
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-1"
                          />
                          <span className="ms-2">processing</span>
                        </div>
                      ) : (
                        <img
                          src={
                            nftstate.nftImages?.length > 0
                              ? nftstate.nftImages[shuffleIndex]?.tempImg
                              : formik.values.layers[0]?.images?.length > 0
                              ? formik.values.layers[0].images[0].image_url
                              : state.collection.layers[0]?.images?.length > 0
                              ? state.collection.layers[0]?.images[0]?.type
                                ? URL.createObjectURL(
                                    state.collection.layers[0].images[0]
                                  )
                                : state.collection.layers[0].images[0]
                              : ""
                          }
                          alt=""
                        />
                      )}
                    </>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center layer-text">
                      Start adding layers to preview one of your NFTs.
                    </div>
                  )}
                </div>
                <div style={{ width: "335px" }}>
                  <div className="text-center mb-2">
                    <button
                      className="shuffle-text"
                      onClick={() => handleShufflePreview()}
                      disabled={nftstate.nftImages?.length === 0 || isShuffle}
                      style={{ cursor: "pointer", background: "transparent" }}
                    >
                      <svg
                        style={{ display: "inline-block", marginRight: "10px" }}
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-rotate-rectangle"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M16.3 5h.7a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h5l-2.82 -2.82m0 5.64l2.82 -2.82"
                          transform="rotate(-45 12 12)"
                        />
                      </svg>
                      Shuffle Preview
                    </button>
                  </div>
                  <div className="mb-5 text-center">
                    <button
                      className="btnGray"
                      onClick={() => {
                        handlePreviewNFT();
                      }}
                      disabled={
                        isShuffle ||
                        isLoading ||
                        nftstate?.nftImages?.length === 0
                      }
                    >
                      {isLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-1"
                        />
                      )}
                      Preview NFT
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {isPreviewModalshow && nftstate.nftImages.length > 0 && (
          <Modal
            show={isPreviewModalshow}
            centered
            onHide={() => {
              setIsPreviewModalShow(false);
              setIsLoading(false);
            }}
            className="preview-nft-model stylishScrollBar"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center mx-auto">Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <Row>
                {nftstate?.nftImages?.length > 0 &&
                  new Array(Math.ceil(nftstate?.nftImages?.length / 3))
                    .fill(0)
                    .map((value, index) => {
                      return (
                        <div>
                          <div className="testing d-flex">
                            {nftstate.nftImages
                              .slice(index * 3, (index + 1) * 3)
                              .map((nftImage, i) => {
                                return (
                                  <Col
                                    key={nftImage.name}
                                    md={4}
                                    className={``}
                                  >
                                    <div
                                      className={`mt-4 ${
                                        isShowNFTDetail[index * 3 + i]
                                          ? "nft-card-details"
                                          : ""
                                      }`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setNftDetail(
                                          _.omit(nftImage, "tempImg")
                                        );
                                        handleNFTDetails(index * 3 + i);
                                      }}
                                    >
                                      <img
                                        src={nftImage.tempImg}
                                        alt=""
                                        className="card-image mb-3"
                                      />
                                      <div
                                        className="mb-2 deploy-card-title text-center"
                                        style={{ fontSize: "15px" }}
                                      >
                                        {nftImage.name}
                                      </div>
                                      <div
                                        className="deploy-card-status text-center"
                                        style={{ color: "#FFFFFF" }}
                                      >
                                        Details
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })}
                          </div>

                          <div className="testing">
                            {nftstate.nftImages
                              .slice(index * 3, (index + 1) * 3)
                              .map((nftImage, i) => {
                                return (
                                  isShowNFTDetail[index * 3 + i] && (
                                    <div className="nftmetadataview mb-3 p-2">
                                      <Row className="mt-3">
                                        {nftImage?.attributes?.map((a, i) => (
                                          <Col
                                            className="mb-3"
                                            xs={6}
                                            sm={6}
                                            md={3}
                                            key={i}
                                          >
                                            <div className="attribute-block">
                                              <span className="attribute-label">
                                                {a.trait_type}
                                              </span>
                                              <span className="attribute-value mb-2">
                                                {a.value}
                                              </span>
                                              {/* <span className="attribute-trait">
                                      {Number(a.percentage).toFixed(2)}% have this
                                      trait
                                    </span> */}
                                            </div>
                                          </Col>
                                        ))}
                                      </Row>
                                    </div>
                                  )
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
              </Row>
            </Modal.Body>
          </Modal>
        )}
        {isShowNFTDetailModal && (
          <Modal
            show={isShowNFTDetailModal}
            centered
            onHide={() => setIsShowNFTDetailsModal(false)}
            className="preview-nft-metadata-model"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center mx-auto">
                Nft Metadata
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {delete nftDetails.tempImg}
                <pre
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(nftDetails, null, 2),
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>
        )}
        {isShowCropImageModal && (
          <Modal
            show={isShowCropImageModal}
            centered
            onHide={() => setIsShowCropImageModal(false)}
            className="preview-nft-metadata-model"
          >
            <Modal.Body>
              <div className="text-center">
                {src && (
                  <div>
                    <ReactCrop
                      src={src}
                      onImageLoaded={setImage}
                      crop={crop}
                      onChange={setCrop}
                    />
                    <br />
                    <button onClick={() => cropImageNow()}>Crop</button>
                    <br />
                    <br />
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        )}
        {imagePreviewModal && (
          <Modal
            show={imagePreviewModal}
            centered
            onHide={() => setImagePreviewModal(false)}
            className="preview-nft-metadata-model"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center mx-auto">
                Image Preview
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center mx-auto">
                {src && <img src={src} alt="" />}
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
      {showPreviewImgModal && (
        <Modal
          show={showPreviewImgModal}
          centered
          onHide={() => setPreviewImgModal(false)}
          className="preview-nft-metadata-model"
        >
          <Modal.Header
            className="d-flex justify-content-end"
            closeButton
          ></Modal.Header>
          <Modal.Body className="d-flex">
            <div className="text-center mx-auto">
              {imageZoom && <img src={imageZoom} alt="" />}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

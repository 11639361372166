export const clusterSolanaApiUrl =
  process.env.REACT_APP_CLUSTER_SOLANA_API_URL!;

export const txUrl = `https://solscan.io/tx/`;

  export const REACT_APP_PINATA_API_KEY = "6f3c09cbc988a582c017";
  export const REACT_APP_PINATA_API_SECRET = "e00818ad8bb377089a22bfc2bf89fbbbfdf568db45922ed942cadf0d63a618a8";
  export const REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL = "https://api.pinata.cloud/pinning/pinFileToIPFS";
  export const REACT_APP_PINATA_PIN_JSON_TO_IPFS_URL = "https://api.pinata.cloud/pinning/pinJSONToIPFS";
  export const REACT_APP_OPEN_SEA_ASSET_CONTRACT_URL = "https://testnets-api.opensea.io/api/v1/asset_contract"

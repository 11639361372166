import {
  cloneDeep,
  forEach,
  isEmpty,
  join,
  map,
  random,
  range,
  round,
  size,
  split,
} from "lodash";
import axios from "axios";
import Web3 from "web3";
import Nft from "../contract/Nft.json";
import { createCanvas, loadImage } from "canvas";
import {
  REACT_APP_PINATA_API_KEY,
  REACT_APP_PINATA_API_SECRET,
  REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
} from "../constants";
import AxiosInstance from "../AxiosRequest";

const headers = {
  pinata_api_key: REACT_APP_PINATA_API_KEY,
  pinata_secret_api_key: REACT_APP_PINATA_API_SECRET,
  "Content-Type": "multipart/form-data",
};

const headers1 = {
  pinata_api_key: REACT_APP_PINATA_API_KEY,
  pinata_secret_api_key: REACT_APP_PINATA_API_SECRET,
  "Content-Type": "application/json",
};

export const handleSubmit = async (values) => {
  var noOfNfts = Number(values.noOfNfts) > 50 ? 50 : Number(values.noOfNfts);
  // Generate Layer Images Master Based on Rarity - Start
  var newImages = map(values.layers, (layer, index) => {
    const noOfLayerImages = round(noOfNfts);
    var layerData = {
      layerName: layer.layerName,
      imageIndexes: [],
    };

    var imageIndex = 0;
    forEach(range(noOfLayerImages), () => {
      if (layer.images[imageIndex]) {
        layerData.imageIndexes.push(imageIndex);
      } else {
        imageIndex = 0;
        layerData.imageIndexes.push(imageIndex);
      }
      imageIndex++;
    });

    const remainingImages = noOfNfts - size(layerData.imageIndexes);
    forEach(range(remainingImages), () => {
      layerData.imageIndexes.push(-1);
    });
    return layerData;
  });
  // Generate Layer Images Master Based on Rarity - End

  // Generate Unique Cobinations for NFT Images - Start
  var nfts = [];
  var uniqueSelectors = [];
  forEach(range(noOfNfts), () => {
    var uniqueCombination = getCombination(newImages, uniqueSelectors);
    if (uniqueCombination) {
      uniqueSelectors.push(uniqueCombination);
    } else {
      return false;
    }
  });
  // Generate Unique Cobinations for NFT Images - End

  // Generate NFT Images - Start
  for (var i = 0; i < uniqueSelectors.length; i++) {
    var metadata = {
      name: `${values.nftSymbol} #${i + 1}`,
      description: `${values.description}`,
      image: null,
      attributes: [],
      properties: {
        files: [],
      },
      tempImg: null,
      symbol: values.nftSymbol,
    };
    var images = split(uniqueSelectors[i], ",");
    const canvas = createCanvas(500, 500);
    const ctx = canvas.getContext("2d");

    var layerIndex = 0;
    for (const imageIndex of images) {
      var nImageIndex = Number(imageIndex);
      if (nImageIndex > -1) {
        if (values.layers[layerIndex]?.attributes) {
          const { layerName, images, attributes } = values.layers[layerIndex];
          const { image_file, image_name } = images[nImageIndex];
          const { background_color } = attributes[nImageIndex];
          if (image_file) {
            const dyImg = await toBase64(image_file);

            const loadedDyImg = await loadImage(dyImg);
            ctx.drawImage(loadedDyImg, 0, 0, 500, 500);

            metadata.attributes.push({
              trait_type: join(split(layerName, "_"), " "),
              value: background_color,
            });
            metadata.properties.files = [
              {
                uri: i + ".png",
                type: image_file.type,
              },
            ];
            metadata.image = i + ".png";
          }
        } else {
          const { layerName, images } = values.layers[layerIndex];
          const { image_file, image_name, background_color } =
            images[nImageIndex];
          if (image_file) {
            const dyImg = await toBase64(image_file);

            const loadedDyImg = await loadImage(dyImg);
            ctx.drawImage(loadedDyImg, 0, 0, 500, 500);

            metadata.attributes.push({
              trait_type: join(split(layerName, "_"), " "),
              value: background_color,
            });
            metadata.properties.files = [
              {
                uri: i + ".png",
                type: image_file.type,
              },
            ];
            metadata.image = i + ".png";
          }
        }
      }

      metadata.tempImg = canvas.toDataURL();
      layerIndex++;
    }

    nfts.push(metadata);
  }

  // const remainingNfts = noOfNfts - size(nfts);
  // var nftIndex = 0;
  // forEach(range(remainingNfts), () => {
  //   if (isEmpty(nfts[nftIndex])) {
  //     nftIndex = 0;
  //   }
  //   const newNft = cloneDeep(nfts[nftIndex]);
  //   newNft.image = size(nfts) + ".png";
  //   newNft.properties.files = [{
  //     uri: size(nfts) + '.png',
  //     type: 'image/png'
  //   }]
  //   newNft.name = `${values.projectName} #${size(nfts)}`;
  //   newNft.description = `${values.description}`;
  //   nfts.push(newNft);
  //   nftIndex++;
  // });

  // Generate NFT Images - End

  return nfts;
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getCombination = (newImages, uniqueSelectors) => {
  var layerIndex = 0;
  var selectedImages = [];
  forEach(newImages, (image) => {
    const selector = random(0, size(image.imageIndexes) - 1);
    const layerImageIndex = image.imageIndexes[selector];
    selectedImages.push(layerImageIndex);

    newImages[layerIndex].imageIndexes.splice(selector, 1);
    layerIndex++;
  });

  var combination = join(selectedImages, ",");
  var isDuplicate = false;
  forEach(uniqueSelectors, (value) => {
    if (value === combination) {
      isDuplicate = true;
      return false;
    }
  });

  if (isDuplicate) {
    if (isEmpty(newImages[0].imageIndexes)) {
      return null;
    }
    return getCombination(newImages, uniqueSelectors);
  }

  return combination;
};

export const handleDeployNFTS = async (
  publicKey,
  creator,
  setState,
  state,
  setContentMenu,
  setIsDepositeLoading,
  setGeneratMsgModal
) => {
  console.log("deploying...");
  // const web3 = new Web3(provider);
  const web3 = new Web3(window.ethereum);
  let deploy_contract = await new web3.eth.Contract(Nft.abi);
  console.log("deploy_contract = ", deploy_contract);
  const params = [
    parseInt(state.collection.no_of_nfts_per_user), //_noOfNFTperuser
    `${state.collection.uri}/`, // Uri
    parseInt(state.collection.no_of_nfts), // _totalSupply
    web3.utils.toWei(state.collection.price_per_nft.toString(), "ether"), // _price
    state.collection.collection_name, // name
    state.collection.nft_handle, // symbol
    true,
  ];

  const payload = {
    arguments: params,
    data: Nft.bytecode.toString(),
  };
  try {
    await deploy_contract
      .deploy(payload)
      .send({
        from: publicKey,
      })
      .on("transactionHash", async function (hash) {
        if (!hash) {
          console.log("transactionHash", hash);
          await AxiosInstance.post("/nftcreator/updateStage", {
            _id: state.collection._id,
            stage: "Deploy",
          });
          setGeneratMsgModal(false);
          setIsDepositeLoading(false);
        }
      })
      .on("confirmation", async (index, contractdetail) => {
        if (contractdetail && contractdetail.contractAddress && index === 3) {
          setState({
            ...state,
            currentStep: 5,
          });
          await AxiosInstance.post("/nftcreator/updateStage", {
            _id: state.collection._id,
            stage: "Ready to Mint",
            contract_address: contractdetail.contractAddress,
          });
          setGeneratMsgModal(false);
          setIsDepositeLoading(false);
          setContentMenu(false);
        }
      });
  } catch (err) {
    console.log(err);
    await AxiosInstance.post("/nftcreator/updateStage", {
      _id: state.collection._id,
      stage: "Deploy",
    });
    setGeneratMsgModal(false);
    setIsDepositeLoading(false);
  }
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl?.split(","),
    mime = arr?.[0]?.match?.(/:(.*?);/)?.[1],
    bstr = atob(arr?.[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {
    type: mime,
  });
};

export const jsontoFile = (data, filename) => {
  var dataurl = `data:application/json;base64,${btoa(JSON.stringify(data))}`;
  return dataURLtoFile(dataurl, filename);
};

const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

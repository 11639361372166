import { Fragment } from "react";
import NewNavbar from "../Navbar";

const WebLayout = ({ children }) => {
  return (
    <Fragment>
      <NewNavbar />
      {children}
    </Fragment>
  );
};

export default WebLayout;

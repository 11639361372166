import { FC, useMemo } from "react";
import CollectionsContextProvider from "./Context/CollectionsContext";
import DeviceContextProvider from "./Context/DeviceContext";
import GridwidthContextProvider from "./Context/GridwidthContext";
import WalletContextProvider from "./Context/WalletContextProvider";
import Web3AuthContextProvider from "./Context/Web3AuthContext";

export const Providers: FC = ({ children }) => {
  // You can also provide a custom RPC endpoint.

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.

  return (
    <DeviceContextProvider>
      <GridwidthContextProvider>
        <Web3AuthContextProvider>
          <CollectionsContextProvider>
            <WalletContextProvider>{children}</WalletContextProvider>
          </CollectionsContextProvider>
        </Web3AuthContextProvider>
      </GridwidthContextProvider>
    </DeviceContextProvider>
  );
};

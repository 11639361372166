import { useContext, useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import AxiosInstance from "../AxiosRequest";
import { authContext } from "../Context/Web3AuthContext";
import icCamera from "../assets/images/Camera.svg";
import axios from "axios";
import { dataURLtoFile, jsontoFile } from "../NFTCreator/CommonMethode";
import { cloneDeep } from "lodash";
import {
  REACT_APP_PINATA_API_KEY,
  REACT_APP_PINATA_API_SECRET,
  REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
  REACT_APP_PINATA_PIN_JSON_TO_IPFS_URL,
} from "../constants";
import Web3 from "web3";
import Nft from "../contract/Nft.json";
import { toast, ToastContainer } from "react-toastify";
import { DeviceContext } from "../Context/DeviceContext";
import placeHolder from "../assets/images/placeholder1.png";
import { WalletContext } from "../Context/WalletContextProvider";

const headers = {
  pinata_api_key: REACT_APP_PINATA_API_KEY,
  pinata_secret_api_key: REACT_APP_PINATA_API_SECRET,
  "Content-Type": "multipart/form-data",
};

const MintEditPage = () => {
  const [collectionDetails, setCollectionDetails] = useState();
  const [collectionImage, setCollectionImage] = useState();
  const { address } = useContext(authContext);
  const [collectionImg, setCollectionImg] = useState(null);
  const [collectionImgUrl, setCollectionImgUrl] = useState("");
  const params = useParams();
  const collectionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [layerIndex, setLayerIndex] = useState(0);
  const [nftcreator, setNFTCreator] = useState();
  const [layerName, setLayerName] = useState();
  const [background_color, setBackgroundColor] = useState();
  const [attributes, setAttributes] = useState([]);
  const { device } = useContext(DeviceContext);
  const [isUpdateImageLoading, setIsUpdateImageLoading] = useState(false);
  const history = useHistory();
  const { getWalletConnectDropDown, getWalletButtonBasedSelectedChain } =
    useContext(WalletContext);

  useEffect(() => {
    if (params?.nft_handle && params.token_id) {
      getData();
    }
  }, [params]);

  useEffect(() => {
    if (params?.nft_handle) {
      getNFTCreatorData();
    }
  }, [params]);

  const handleCollectionImage = (e) => {
    if (e.target.files[0]) {
      setCollectionImg(e.target.files[0]);
      setCollectionImgUrl(URL.createObjectURL(e.target.files[0]));
      collectionRef.current.value = "";
    }
  };

  const getNFTCreatorData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getDataBySymbol?nft_handle=${params.nft_handle}`
    );
    if (response.status === 200) {
      if (response.data && response.data.data) {
        setNFTCreator(response.data.data);
      }
    }
  };

  const getData = async () => {
    const response = await AxiosInstance.post(
      "/nfts/getNFTByTokenIdAndSymbol",
      {
        token_id: params.token_id,
        nft_handle: params.nft_handle,
      }
    );
    if (response.status === 200) {
      setCollectionDetails(response.data.data);
    }
  };

  const getLayerNameByTraitType = (value) => {
    setLayerName(value);
    if (nftcreator?.layers?.length > 0) {
      for (let i = 0; i < nftcreator.layers.length; i++) {
        if (nftcreator.layers[i].layerName === value) {
          setLayerIndex(i);
          break;
        }
      }
    }
  };

  const getRarityValue = async (layerName, background_color) => {
    let rarity = 0;
    for (let i = 0; i < nftcreator.layers.length; i++) {
      if (nftcreator.layers[i].layerName === layerName) {
        for (let j = 0; j < nftcreator.layers[i].attributes.length; j++) {
          if (
            nftcreator.layers[i].attributes[j].background_color ==
            background_color
          ) {
            rarity = nftcreator.layers[i].attributes[j].rarity;
          }
        }
      }
    }
    return rarity;
  };

  const getNFTImage = async (value) => {
    setIsUpdateImageLoading(true);
    let layers = [];
    let array = collectionDetails.attributes;
    let attributes = collectionDetails.attributes;
    if (nftcreator?.layers?.length > 0) {
      for (let i = 0; i < collectionDetails.attributes.length; i++) {
        layers.push({
          name: collectionDetails.attributes[i].trait_type,
        });
        if (collectionDetails.attributes[i].trait_type === layerName) {
          attributes[i].trait_type = layerName;
          attributes[i].value = value;
          attributes[i].rarity = await getRarityValue(layerName, value);
        } else {
          attributes[i] = collectionDetails.attributes[i];
          attributes[i].rarity = await getRarityValue(
            collectionDetails.attributes[i].trait_type,
            collectionDetails.attributes[i].value
          );
        }
        array[i] = attributes[i];
        layers[i].attributes = attributes[i];
      }
    }
    setAttributes(array);
    await AxiosInstance.post(
      `/nftcreator/getNftImage?folderName=${
        nftcreator.folderName.split("/")[2]
      }`,
      {
        layers: layers,
        id: nftcreator._id,
        token_id: collectionDetails.token_id,
      }
    );
    setCollectionImgUrl(
      `${process.env.REACT_APP_API_URL}/${
        nftcreator.folderName.split("/")[2]
      }/dynamicnfts/${collectionDetails.token_id}.png?${Date.now()}`
    );
    setIsUpdateImageLoading(false);
    setBackgroundColor(value);
  };

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const handleSubmit = async () => {
    const data = await toDataURL(collectionImgUrl);
    setIsLoading(true);
    const iFormData = new FormData();
    iFormData.append(
      "file",
      dataURLtoFile(data, `${collectionDetails.token_id}.png`),
      `${nftcreator.collection_name}_Assets/${collectionDetails.token_id}.png`
    );

    const iResponse = await axios({
      method: "post",
      url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
      data: iFormData,
      headers,
    });

    const jFormData = new FormData();

    if (iResponse.data) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${
          nftcreator.folderName.split("/")[2]
        }/dynamicnfts/${collectionDetails.token_id}.json`
      );
      const nftMetadata = response.data;
      nftMetadata.image = `ipfs://${iResponse.data.IpfsHash}/${collectionDetails.token_id}.png`;

      let obj = {};
      obj = {
        image: `ipfs://${iResponse.data.IpfsHash}/${collectionDetails.token_id}.png`,
        name: `${nftcreator.collection_name} #${collectionDetails.token_id}`,
        attributes: attributes,
      };

      jFormData.append(
        "file",
        jsontoFile(nftMetadata, `${collectionDetails.token_id}.json`),
        `${nftcreator.collection_name}_Metadata/${collectionDetails.token_id}.json`
      );

      const jResponse = await axios({
        method: "post",
        url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
        data: jFormData,
        headers,
      });
      // const metadata = await axios.get(
      //   `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}/${collectionDetails.token_id}.json`
      // );
      await AxiosInstance.post("/nfts/update", {
        id: collectionDetails._id,
        metadata_url: `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}`,
        attributes: response.data.attributes,
        image_url: `${process.env.REACT_APP_API_URL}/${
          nftcreator.folderName.split("/")[2]
        }/dynamicnfts/${collectionDetails.token_id}.png`,
      });
      const web3 = new Web3(window.ethereum);
      const nftContract = new web3.eth.Contract(
        Nft.abi,
        nftcreator.contract_address
      );
      const tx = await nftContract.methods
        .SetTokenURI(
          collectionDetails.token_id,
          `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}`
        )
        .send({
          from: address,
        });
      toast.success("NFT Updated Successfully");
      setIsLoading(false);
      history.push("/minted-nfts");
    }
  };

  const handleError = (e) => {
    e.target.src = placeHolder;
  };

  return (
    <div className="nft-creator">
      <ToastContainer />
      {!address ? (
        <div
          className="mx-5 d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          {getWalletConnectDropDown()}
          {getWalletButtonBasedSelectedChain()}
        </div>
      ) : (
        <>
          <div
            style={{
              maxWidth: "14000px",
              paddingBottom: "10px",
              paddingTop: "190px",
              minHeight: "100vh",
            }}
          >
            <div className="container grayBox mt-5">
              <div
                className="collection-details mb-5"
                style={{ minHeight: "200px" }}
              >
                <Row>
                  <Col xl={3} lg={3} sm={12} className="d-flex">
                    {isUpdateImageLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          height: "280px",
                          width: "280px",
                          border: "1px solid #000000",
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-1"
                        />
                        <span className="ms-2 text-white">processing</span>
                      </div>
                    ) : (
                      <img
                        className="mint-nftimage"
                        src={
                          collectionImgUrl
                            ? collectionImgUrl
                            : collectionDetails?.image_url
                            ? collectionDetails.image_url
                            : placeHolder
                        }
                        style={{ width: "280px", height: "280px" }}
                        alt=""
                        onError={handleError}
                      />
                    )}
                  </Col>
                  <Col xl={7} lg={7} sm={12} className="ms-4">
                    <div className="mb-4">
                      <div className="d-flex collection-description-details">
                        <div>
                          {device !== "mobile" && (
                            <div className="nft-name mb-1 mt-1">
                              {collectionDetails && collectionDetails.name}
                            </div>
                          )}
                          <div
                            className="label-name"
                            style={{ color: "#000000" }}
                          >
                            {collectionDetails && collectionDetails.nft_handle}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col md={12} lg={6} className="mb-5">
                  <Form.Label className="form-label mb-2">Traits</Form.Label>
                  <Form.Control
                    as="select"
                    className="form-input-text mt-2 mb-2"
                    value={layerName}
                    onChange={(e) => {
                      getLayerNameByTraitType(e.target.value);
                    }}
                  >
                    <>
                      <option disabled selected>
                        Select Traits
                      </option>
                      {collectionDetails?.attributes?.length > 0 &&
                        collectionDetails.attributes.map((data, i) => {
                          return <option key={i}>{data.trait_type}</option>;
                        })}
                    </>
                  </Form.Control>
                </Col>
                {layerName && (
                  <Col md={12} lg={6} className="mb-5">
                    <Form.Label className="form-label mb-2">Value</Form.Label>
                    <Form.Control
                      as="select"
                      className="form-input-text mt-2 mb-2"
                      value={attributes[layerIndex]?.value}
                      onChange={(e) => {
                        getNFTImage(e.target.value);
                      }}
                    >
                      <>
                        <option disabled selected>
                          Select Value
                        </option>
                        {nftcreator?.layers?.[layerIndex]?.attributes?.length >
                          0 &&
                          nftcreator.layers[layerIndex].attributes.map(
                            (data, i) => {
                              return (
                                <option
                                  key={`value ${i}`}
                                  selected={
                                    attributes[layerIndex]?.value ==
                                    data.background_color
                                  }
                                  value={data.background_color}
                                >
                                  {data.background_color}
                                </option>
                              );
                            }
                          )}
                      </>
                    </Form.Control>
                  </Col>
                )}
              </Row>
              <Row className="my-3 mb-5">
                <Col md={12} className="d-flex justify-content-end flex-wrap">
                  {collectionDetails &&
                  address !==
                    collectionDetails.owner_publickey.toLowerCase() ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="btnGray"
                      onClick={handleSubmit}
                      disabled={
                        isLoading ||
                        background_color === null ||
                        background_color === "" ||
                        background_color === undefined
                      }
                      style={{ width: "210px", padding: "0" }}
                    >
                      {isLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-1"
                        />
                      )}
                      Update
                    </button>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MintEditPage;

import React, { useEffect, useState } from "react";

export const GridwidthContext = React.createContext();

const GridwidthContextProvider = ({ children }) => {
  const [width, setWidth] = useState(244);
  const [size, setSize] = useState(0);
  const [layout, setLayout] = useState("min");

  const isBetween = (x, min, max) => {
    return x >= min && x <= max;
  };

  useEffect(() => {
    let maxItems = 8;
    if (isBetween(size, 2560, 5560)) {
      maxItems = 8;
      if (layout === "min") maxItems = maxItems + 4;
      else maxItems = 8;
    }

    if (isBetween(size, 2450, 2559)) {
      maxItems = 7;
      if (layout === "min") maxItems = maxItems + 4;
      else maxItems = 7;
    }

    if (isBetween(size, 2160, 2449)) {
      maxItems = 6;
      if (layout === "min") maxItems = maxItems + 4;
      else maxItems = 6;
    }

    if (isBetween(size, 1860, 2159)) {
      maxItems = 5;
      if (layout === "min") maxItems = maxItems + 4;
      else maxItems = 5;
    }

    if (isBetween(size, 1570, 1859)) {
      maxItems = 4;
      if (layout === "min") maxItems = maxItems + 3;
      else maxItems = 4;
    }

    if (isBetween(size, 1280, 1569)) {
      maxItems = 3;
      if (layout === "min") maxItems = maxItems + 3;
      else maxItems = 3;
    }

    if (isBetween(size, 990, 1279)) {
      maxItems = 2;
      if (layout === "min") maxItems = maxItems + 1;
      else maxItems = 2;
    }

    if (isBetween(size, 0, 989)) {
      maxItems = 2;
      // if (layout === "min") maxItems = maxItems + 2;
      // else maxItems = 2;
    }

    const width = size / maxItems;
    setWidth(width);
  }, [layout, size]);

  const handleResize = (size, layout) => {
    setSize(size);
    setLayout(layout);
  };

  return (
    <GridwidthContext.Provider value={{ width, handleResize }}>
      {children}
    </GridwidthContext.Provider>
  );
};

export default GridwidthContextProvider;

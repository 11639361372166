import React, { Fragment, useState } from "react";
import "./index.scss";
import CreateNft from "./CreateNFT";

const NFTCreator = () => {
  const [state, setState] = useState({
    currentStep: 1,
    folderName: "",
    collection: {},
    validationError: "",
  });

  return (
    <Fragment>
      <div className="nft-creator">
        <div
          className="container"
          style={{ maxWidth: "1400px", paddingTop: "190px" }}
        >
          <CreateNft state={state} setState={setState} />
        </div>
      </div>
    </Fragment>
  );
};

export default NFTCreator;

import { Fragment, useContext, useEffect, useState } from "react";
import "./index.scss";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import Image1 from "../assets/images/NFTGenerator1.png";
import Image2 from "../assets/images/NFTGenerator2.png";
import Image3 from "../assets/images/NFTGenerator3.png";
import Image4 from "../assets/images/NFTGenerator4.png";
import { authContext } from "../Context/Web3AuthContext";
import { toast, ToastContainer } from "react-toastify";

export default function SelectType({ next, formik, setState }) {
  const [activeCard, setActiveCard] = useState(-1);
  const { address } = useContext(authContext)

  const handleClick = (index) => {
    if(!address) {
      toast.error("Please connect your wallet")
    } else {
      setActiveCard(index);
      let typeValue;
      switch (index) {
        case 0:
          typeValue = "layered";
          break;
        case 1:
          typeValue = "video";
          break;
        case 2:
          typeValue = "document";
          break;
        default:
          typeValue = "music";
      }

      formik.setFieldValue("type", typeValue);
      // if (index === 0) {
      next();
      // }
    }
  };

  const nftTypeData = [
    {
      title: "Layered NFT Creation (Images)",
      icon: Image1,
      description:
        "Select this option if you have various layers that you can assign rarity to, and combine them to make generative art.",
      button1: "Live",
    },
    {
      title: "Video",
      icon: Image2,
      description:
        "Select this option if you want to create a video file. Limited to 30mb in size.",
      // button1: "Coming Soon",
      button1: "Live",
    },
    {
      title: "Documents",
      icon: Image3,
      description:
        "Select this options if you want to create a document (eg. A contract).",
      // button1: "Coming Soon",
      button1: "Live",
    },
    {
      title: "Music",
      icon: Image4,
      description: "Select this option if you want to create an audio file.",
      // button1: "Coming Soon",
      button1: "Live",
    },
  ];

  useEffect(() => {
    // console.log("resetting the form", formik.values);
    // console.log("after = ", formik.values);
    formik.resetForm();
    setState({
      currentStep: 1,
      folderName: "",
      collection: {},
      validationError: "",
    });
  }, []);

  return (
    <div className="row">
      <ToastContainer />
      <div className="d-flex justify-content-between col-xl-12 col-lg-12 col-md-12">
        <div className="">
          <Form>
            <Row>
              <Col md={12}>
                <div className="structureContainer row">
                  {nftTypeData.map((item, i) => (
                    <div key={i} className="mb-4 col-6">
                      {/* <input
                        type="radio"
                        name="radio"
                        id={`nftId${i + 1}`}
                        defaultChecked={i === 0}
                      ></input> */}
                      <label
                        htmlFor={`nftId${i + 1}`}
                        className={`subscription__button ${
                          i === activeCard ? "active" : ""
                        } `}
                        onClick={() => handleClick(i)}
                      >
                        <div>
                          <div className="d-flex">
                            <div style={{ maxWidth: "150px" }}>
                              <img className="icon mb-4" src={item.icon} />
                              {item.button1 === "Live" && (
                                <button type="button" className="btnGray">
                                  {item.button1}
                                </button>
                              )}
                            </div>
                            <div className="ms-4 mt-2">
                              <h3 className="subscription__title">
                                {item.title}
                              </h3>
                              <p className="desc mt-3">{item.description}</p>
                            </div>
                          </div>
                          {item.button1 !== "Live" && (
                            <button className="whiteBtn">{item.button1}</button>
                          )}
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
              <div className="w-100 mb-5 d-flex justify-content-end">
                <button
                  className="btnGray"
                  onClick={next}
                  style={{ width: "210px", padding: "0" }}
                  disabled={formik.values.type === "" || !address}
                >
                  Continue
                </button>
              </div>
            </Row>
          </Form>
        </div>
        {/* <div className="mx-3 d-flex">
          {getWalletConnectDropDown()}
          {getWalletButtonBasedSelectedChain()}
        </div> */}
      </div>
    </div>
  );
}

import { Fragment } from "react";
import Select, { components } from "react-select";
import ArrowImg from "../media/img/arrow.svg";

const CommonSelect = ({ items, value, onChange }) => {
  return (
    <Fragment>
      <Select
        styles={{
          container: (provided) => ({ ...provided, marginLeft:"0" }),
          control: () => ({
            backgroundColor: "#161616",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            borderRadius: "50px",
            minHeight: "50px",
            display: "flex",
            color: "#fff",
            padding: "10px",
            cursor: "pointer",
          }),
          singleValue: (provided) => ({
            ...provided,
            cursor: "pointer",
            color: "#fff",
          }),
          input: (provided) => ({
            ...provided,
            color: "#fff",
          }),
          menu: (provided) => ({
            ...provided,
            border: "1px solid rgba(255, 255, 255, 0.17)",
            padding: "10px",
            borderRadius: "23px",
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(10px)",
          }),
          menuList: () => ({
            fontFamily: "Prompt",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "28px",
            color: "#ffffff",
          }),
          dropdownIndicator: (provided, state) => {
            return {
              ...provided,
              transition: "0.3s",
              transform: state.selectProps.menuIsOpen
                ? "rotate(0deg)"
                : "rotate(180deg)",
            };
          },
        }}
        components={{
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: () => null,
          Option: (props) => <Option {...props} />,
          Control: ({ children, ...rest }) => (
            <components.Control {...rest}>{children}</components.Control>
          ),
        }}
        value={items.find((i) => i.value === value)}
        isMulti={false}
        isSearchable={false}
        options={items}
        menuPlacement="auto"
        onChange={(newValue) => {
          if (typeof onChange === "function") onChange(newValue);
        }}
        classNamePrefix="rp-common-select"
      />
    </Fragment>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={ArrowImg} alt="Arrow" />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  const { data, innerProps } = props;
  return (
    <div {...innerProps} className="d-flex px-3 py-2 cursor-pointer">
      <span className="me-2">{data.label}</span>
    </div>
  );
};

export default CommonSelect;

import axios from "axios";

const getTokens = () => {
  const accessToken = localStorage.getItem("token");
  return {
    accessToken,
  };
};

export const getHeader = () => {
  const token = getTokens();
  if (token && token.accessToken)
    return {
      Authorization: token.accessToken,
    };
};

// Create Instance For Api Call.
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // headers: {
  //   ...getHeader(),
  // },
});

AxiosInstance.interceptors.request.use(function (config) {
  config.params = config.params || {};
  config.params['t'] = new Date().valueOf();
  if (localStorage.getItem("token")) {
    // config.headers.Authorization = window.authToken;
    config.headers.Authorization = localStorage.getItem("token");
  }
  return config;
});

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw error;
  }
);

export default AxiosInstance;

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AxiosInstance from "../AxiosRequest";
import { authContext } from "../Context/Web3AuthContext";
import VideoPlaceholder from "../assets/images/video-placeholder.png";
import placeHolder from "../assets/images/placeholder1.png";
import { WalletContext } from "../Context/WalletContextProvider";
const MintedNFTS = () => {
  const [nfts, setNFTs] = useState([]);
  const { address } = useContext(authContext);
  const history = useHistory();
  const { getWalletConnectDropDown, getWalletButtonBasedSelectedChain } =
    useContext(WalletContext);

  useEffect(() => {
    getMintedNFTs();
  }, []);

  const getMintedNFTs = async () => {
    try {
      const response = await AxiosInstance.get("/nfts/fetchAllNfts");
      setNFTs(response.data.data);
    } catch (err) {
      throw err;
    }
  };

  const placeHolderImage = (data) => {
    if (data.type === "music") {
      return "https://gateway.pinata.cloud/ipfs/QmWh9PGx7JrXQ5w4pVfxhMmCe9rqgGUrourWD1WMTjN5kw";
    } else if (data.type === "document") {
      return "https://gateway.pinata.cloud/ipfs/QmSVddcmy4ohnvD34EQiKtC7gZPNZuAUiJ415ar33z1w7N";
    } else if (data.type === "layered") {
      return data.image_url;
    } else {
      return VideoPlaceholder;
    }
  };

  const handleError = (e) => {
    e.target.src = placeHolder;
  };

  return (
    <div className="nft-creator">
      {console.log(address)}
      {!address ? (
        <div
          className="mx-5 d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          {getWalletConnectDropDown()}
          {getWalletButtonBasedSelectedChain()}
        </div>
      ) : (
        <div
          style={{
            maxWidth: "14000px",
            paddingBottom: "10px",
            paddingTop: "190px",
            minHeight: "100vh",
          }}
        >
          <div className="container grayBox">
            <div className="mb-5 nft-name">Minted NFTs</div>
            <Row>
              {nfts.length > 0 &&
                nfts.map((data, i) => {
                  return (
                    <Col md={3} lg={3} className="mb-5">
                      <Card className="custom-card">
                        <Card.Img
                          src={placeHolderImage(data)}
                          onError={handleError}
                        />
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <div className="mb-3 mt-2 title-text d-flex justify-content-center align-items-center">
                              {data.name}
                            </div>
                            {address == data.owner_publickey.toLowerCase() &&
                              data.type === "layered" && (
                                <div>
                                  <button
                                    className="btnGray"
                                    onClick={() =>
                                      history.push(
                                        `/${data.nft_handle}/edit/${data.token_id}`
                                      )
                                    }
                                  >
                                    Edit
                                  </button>
                                </div>
                              )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};
export default MintedNFTS;

import { useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import Twitter from "../assets/images/twitter.svg";
import Discord from "../assets/images/socialIcon/collection_discord.svg";
import Website from "../media/img/website.png";
import icCamera from "../assets/images/Camera.svg";
import AxiosInstance from "../AxiosRequest";
import { uploadToS3 } from "../utils/aws";
import moment from "moment";

const AboutCollection = ({
  state,
  next,
  publicKey,
  setState,
  setSubMenuIndex,
  setContentMenu,
  contentMenu,
}) => {
  const collectionRef = useRef(null);

  const [values, setValues] = useState({});
  const [collectionImg, setCollectionImg] = useState(null);
  const [collectionImgUrl, setCollectionImgUrl] = useState("");
  const [collectionDetails, setCollectionDetails] = useState({});
  const [aboutErrorMessage, setAboutErrorMessage] = useState("");
  const [twitterErrorMessage, setTwitterErrorMessage] = useState("");
  const [discordErroeMessage, setDiscordErrorMessage] = useState("");
  const [bioErrorMessage, setBioErrorMessage] = useState("");
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState("");
  const [teams, setTeams] = useState([
    {
      teamname: "",
      twitter_id: "",
      discord_id: "",
    },
  ]);
  const [collectionErrorMessage, setCollectionErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getData?id=${state.collection._id}`
    );
    if (response.status === 200) {
      if (response.data && response.data.data) {
        setCollectionDetails(response.data.data);
        if (response.data.data?.collection_image) {
          setCollectionImgUrl(response.data.data.collection_image);
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
    if (name === "about") {
      if (value === "") {
        setAboutErrorMessage("This field is required");
      } else if (value.length < 400) {
        setAboutErrorMessage("Please add min 400 characters");
      } else {
        setAboutErrorMessage("");
      }
    }
    if (name === "twitter_id") {
      if (value === "") {
        setTwitterErrorMessage("This field is required");
      } else if (value.match(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?]/)) {
        setTwitterErrorMessage("Please do not add any special characters");
      } else {
        setTwitterErrorMessage("");
      }
    }
    if (name === "discord_id") {
      if (value.match(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?]/)) {
        setDiscordErrorMessage("Please do not add any special characters");
      } else {
        setDiscordErrorMessage("");
      }
    }
    if (name === "bio") {
      if (value == "") {
        setBioErrorMessage("This field is required");
      } else {
        setBioErrorMessage("");
      }
    }
    if (name === "website") {
      if (value !== "" && !value.match(/http(?:s)?:\/\/([a-zA-Z0-9_]+)/)) {
        setWebsiteErrorMessage("must include https://");
      } else {
        setWebsiteErrorMessage("");
      }
    }
  };

  const handleCollectionImage = (e) => {
    if (e.target.files[0]) {
      setCollectionImg(e.target.files[0]);
      setCollectionImgUrl(URL.createObjectURL(e.target.files[0]));
      setCollectionErrorMessage("");
      collectionRef.current.value = "";
    } else if (!collectionImgUrl) {
      setCollectionErrorMessage("This field is required");
    }
  };

  const handleSubmit = async () => {
    try {
      let errorMessage = "";
      if (!values?.about && !state.collection?.about) {
        setAboutErrorMessage("This Field is required");
      } else if (
        values?.about?.length < 400 &&
        state.collection?.about?.length < 400
      ) {
        setAboutErrorMessage("Please add min 400 characters");
      } else {
        setAboutErrorMessage("");
      }
      if (!values?.twitter_id && !state.collection?.twitter_url) {
        setTwitterErrorMessage("This field is required");
      } else {
        setTwitterErrorMessage("");
      }

      if (!values?.bio && !state.collection?.bio) {
        setBioErrorMessage("This field is required");
      } else {
        setBioErrorMessage("");
      }

      if (collectionImg) {
        if (
          (values?.about || state.collection?.about) &&
          (values?.twitter_id || state.collection?.twitter_url) &&
          discordErroeMessage.length === 0 &&
          (values?.bio || state.collection?.bio) &&
          websiteErrorMessage.length === 0 &&
          errorMessage.length === 0
        ) {
          setIsLoading(true);
          const _data = await uploadToS3(collectionImg, "profile");
          values.collection_image = _data.location;
          setCollectionErrorMessage("");
        }
      } else if (
        !values.collection_image &&
        !collectionImg &&
        !collectionImgUrl &&
        !state.collection?.collection_image
      ) {
        setCollectionErrorMessage("This field is required");
      }

      if (
        (values?.about || state.collection?.about) &&
        (values?.twitter_id || state.collection?.twitter_url) &&
        discordErroeMessage.length === 0 &&
        (values?.bio || state.collection?.bio) &&
        websiteErrorMessage.length === 0 &&
        (values?.collection_image || state.collection?.collection_image) &&
        errorMessage.length === 0
      ) {
        await getCollectionDetails();
      }
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const getCollectionDetails = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id: collectionDetails._id,
        bio: state.collection?.bio ? state.collection.bio : values.bio,
        about: state.collection?.about ? state.collection.about : values.about,
        twitter_id: state.collection?.twitter_url
          ? state.collection.twitter_url
          : values.twitter_id,
        discord_id: state.collection?.discord_url
          ? state.collection.discord_url
          : values.discord_id,
        website: state.collection?.website
          ? state.collection.website
          : values.website,
        whitepaper: state.collection?.whitepaper
          ? state.collection.whitepaper
          : values.whitepaper,
        collection_image: state.collection?.collection_image
          ? state.collection.collection_image
          : values.collection_image,
      };
      const response = await AxiosInstance.post(
        `/nftcreator/collectionDetails?creator=${publicKey}`,
        payload
      );

      if (response.data.statusText === "Success" && response.status === 200) {
        // history.push(`/${response.data.data.nft_handle}/mint/${params.id}`
        setState({
          ...state,
          collection: response.data.data,
          currentStep: 4,
        });
        setSubMenuIndex(0);
        setContentMenu(!contentMenu);
        setIsLoading(false);
        next();
      }
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  return (
    <div className="row">
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="grayBox">
          <Row className="">
            <Col lg={6}>
              <div className="mb-5">
                <Form.Label className="form-label">
                  NFT Collection Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="collection_name"
                  className="form-input-text mt-2 mb-2"
                  placeholder="Enter name"
                  onChange={(e) => {
                    onFormChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                      },
                    });
                  }}
                  value={state.collection.collection_name}
                  disabled
                />
              </div>
              <div className="mb-5">
                <Form.Label className="form-label">Short Bio</Form.Label>
                <Form.Control
                  type="text"
                  name="bio"
                  className="form-input-text mt-2 mb-2"
                  placeholder="Max 300 characters"
                  onChange={(e) => {
                    onFormChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                        bio: e.target.value,
                      },
                    });
                  }}
                  required
                  value={
                    state.collection?.bio
                      ? state.collection.bio
                      : values && values.bio
                  }
                />
                {bioErrorMessage.length > 0 && (
                  <span className="text-danger">{bioErrorMessage}</span>
                )}
              </div>
              <div className="mb-5">
                <label className="rp-form-label">Social</label>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">Your Twitter Handle</Tooltip>
                  }
                >
                  <div className="rp-input-links mb-3">
                    <img src={Twitter} alt="" />
                    <input
                      type="text"
                      name="twitter_id"
                      placeholder="YourTwitterHandle"
                      className="rp-input-f-group w-100 ms-2"
                      onChange={(e) => {
                        onFormChange(e);
                        setState({
                          ...state,
                          collection: {
                            ...state.collection,
                            twitter_url: e.target.value,
                          },
                        });
                      }}
                      required
                      value={
                        state.collection?.twitter_url
                          ? state.collection.twitter_url
                          : values && values.twitter_id
                      }
                    />
                  </div>
                </OverlayTrigger>
                {twitterErrorMessage.length > 0 && (
                  <span className="text-danger">{twitterErrorMessage}</span>
                )}

                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">Your Discord Handle</Tooltip>
                  }
                >
                  <div className="rp-input-links mt-3">
                    <img src={Discord} alt="" />
                    <input
                      type="text"
                      name="discord_id"
                      placeholder="YourDiscord#Handle"
                      className="rp-input-f-group w-100 ms-2"
                      onChange={(e) => {
                        onFormChange(e);
                        setState({
                          ...state,
                          collection: {
                            ...state.collection,
                            discord_url: e.target.value,
                          },
                        });
                      }}
                      value={
                        state.collection?.discord_url
                          ? state.collection?.discord_url
                          : values && values.discord_id
                      }
                    />
                  </div>
                </OverlayTrigger>
                {discordErroeMessage.length > 0 && (
                  <span className="text-danger">{discordErroeMessage}</span>
                )}
              </div>
              <div className="mb-5">
                <label className="rp-form-label">Website</label>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">Website</Tooltip>}
                >
                  <div className="rp-input-links">
                    <img src={Website} alt="" />
                    <input
                      type="text"
                      name="website"
                      placeholder="YourWebsite"
                      className="rp-input-f-group w-100 ms-2"
                      onChange={(e) => {
                        onFormChange(e);
                        setState({
                          ...state,
                          collection: {
                            ...state.collection,
                            website: e.target.value,
                          },
                        });
                      }}
                      value={
                        state.collection?.website
                          ? state.collection.website
                          : values && values.website
                      }
                    />
                  </div>
                </OverlayTrigger>
                {websiteErrorMessage.length > 0 && (
                  <span className="text-danger">{websiteErrorMessage}</span>
                )}
              </div>
              <div className="mb-5">
                <label className="rp-form-label">Whitepaper</label>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">Whitepaper</Tooltip>}
                >
                  <div className="rp-input-links">
                    {/* <img src={Website} alt="" /> */}
                    <input
                      type="text"
                      name="whitepaper"
                      placeholder="Whitepaper"
                      className="rp-input-f-group w-100 ms-2"
                      onChange={(e) => {
                        onFormChange(e);
                        setState({
                          ...state,
                          collection: {
                            ...state.collection,
                            whitepaper: e.target.value,
                          },
                        });
                      }}
                      value={
                        state.collection?.whitepaper
                          ? state.collection.whitepaper
                          : values && values.whitepaper
                      }
                    />
                  </div>
                </OverlayTrigger>
              </div>
              <div className="mb-5">
                <Form.Label className="form-label">About</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="about"
                  className="form-input-text mt-2 mb-2"
                  placeholder="Describe your collection"
                  rows={10}
                  cols={10}
                  onChange={(e) => {
                    onFormChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                        about: e.target.value,
                      },
                    });
                  }}
                  required
                  value={
                    state.collection?.about
                      ? state.collection.about
                      : values && values.about
                  }
                />
                {aboutErrorMessage.length > 0 && (
                  <span className="text-danger">{aboutErrorMessage}</span>
                )}
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <div className="mb-3">
                <label className="rp-form-label">Collection Image</label>

                <button
                  className="file-button"
                  type="button"
                  style={{
                    backgroundImage: `url(${collectionImgUrl})`,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  onClick={() => collectionRef.current.click()}
                >
                  <img src={icCamera} alt="..." className="plus-icon" />
                  {/* {collectionImgUrl ? (
                    <img
                      src={RemoveIcon}
                      alt="..."
                      style={{ width: "26px", position: "absolute" }}
                      className="plus-icon ms-3"
                      onClick={() => {
                        collectionRef.current.close();
                        setCollectionImgUrl("");
                        setCollectionImg();
                      }}
                    />
                  ) : null} */}
                </button>
                <p className="profile-info-text">
                  Recommended 1080x1080px & Max Size 5mb
                </p>
                <input
                  type={"file"}
                  ref={collectionRef}
                  accept="image/*"
                  onChange={handleCollectionImage}
                  className="hidden-file"
                />
                {collectionErrorMessage.length > 0 && (
                  <span className="text-danger">{collectionErrorMessage}</span>
                )}
              </div>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col md={12} lg={12}>
              <div className="d-flex justify-content-end">
                <button
                  className="btnGray"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-1"
                    />
                  )}
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default AboutCollection;

import React, { Component } from "react";
import axios from "axios";
import AxiosInstance from "../AxiosRequest";
import { useState } from "react";

export const CollectionsContext = React.createContext();

const CollectionsContextProvider = ({children}) => {
  const [previousNfts, setPreviousNFTs] = useState({
    isLoading: false,
    total: 0,
    next: false,
    prev: false,
    data: [],
  });

  const getPreviousNFTs = async (creator, page, limit) => {
    setPreviousNFTs({
      ...previousNfts,
      isLoading: true,
    });
    try {
      const { data } = await AxiosInstance.post("/nftcreator/previousNFTs", {
        creator,
        page,
        limit,
      });
      setPreviousNFTs({
        ...previousNfts,
        isLoading: false,
        next: data.data.next,
        prev: data.data.prev,
        total: data.data.total || 0,
        data: data.data.data || [],
      });
    } catch (err) {
      return err;
    }
  };

  return (
    <CollectionsContext.Provider value={{ previousNfts, getPreviousNFTs }}>
      {children}
    </CollectionsContext.Provider>
  );
};

export default CollectionsContextProvider;

import moment from "moment";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { Table, Modal, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import CommonSelect from "../CommonSelect";
import { CollectionsContext } from "../Context/CollectionsContext";
import AxiosInstance from "../AxiosRequest";
import {toast, ToastContainer} from "react-toastify";

const PreviousNFTs = ({
  state,
  setState,
  setSubMenuIndex,
  contentMenu,
  setContentMenu,
  publicKey,
}) => {
  const { getPreviousNFTs, previousNfts } = useContext(CollectionsContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [withdrawModal, setIsWithdrawModal] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (publicKey) {
      getPreviousNFTs(publicKey, page, limit);
    }
  }, [publicKey, page, limit]);

  const withdrawBalance = async (id, toWallet) => {
    setIsWithdrawModal(true);
    const response = await AxiosInstance.post(`nftcreator/withdrawBalance`, {
      id: id,
      to: toWallet.toString(),
    });
    console.log(response.status);
    console.log(response);
    if (response.data.success) {
      getPreviousNFTs(publicKey.toBase58(), page, limit);
      setIsWithdrawModal(false);
      toast.success("Withdraw success!");
    } else {
      setIsWithdrawModal(false);
      toast.error("Something went wrong.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="grayBox">
            <Table
              striped
              bordered
              className="collection-tabel table-responsive"
            >
              <thead>
                <tr>
                  <th className="header-text">Collection Name</th>
                  <th className="header-text">Status</th>
                  <th className="header-text">Action</th>
                  <th className="header-text">Date</th>
                </tr>
              </thead>
              <tbody>
                {previousNfts.isLoading ? (
                  <tr>
                    <td colSpan={6}>
                      <div className="text-center">Please wait...</div>
                    </td>
                  </tr>
                ) : (
                  <Fragment>
                    {previousNfts.data.length > 0 &&
                      previousNfts.data.map((collection, index) => {
                        const folder = collection?.folderName;
                        const folderName = folder?.split("/");
                        return (
                          <tr key={index}>
                            <td className="text-center">
                              <div className="col d-flex align-items-center tabel-body-text">
                                {collection.collection_name}
                              </div>
                            </td>
                            <td className="text-white">
                              {collection && collection.deployed_failed
                                ? "Redeploy"
                                : collection?.stage
                                ? collection.stage
                                : "-"}
                            </td>
                            <td>
                              {collection &&
                              collection.stage &&
                              collection.stage == "NFTDetails" ? (
                                <button
                                  className="text-white continueBtn"
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      currentStep: 2,
                                      collection: collection,
                                      folderName: folderName?.[2],
                                    })
                                  }
                                >
                                  Continue to {collection.stage}
                                </button>
                              ) : null}
                              {collection &&
                              collection.stage &&
                              collection.stage == "Traits" ? (
                                <button
                                  className="text-white continueBtn"
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      currentStep: 4,
                                      collection: collection,
                                      folderName: folderName?.[2],
                                    });
                                    setSubMenuIndex(0);
                                    setContentMenu(!contentMenu);
                                  }}
                                >
                                  Continue to {collection.stage}
                                </button>
                              ) : null}
                              {collection &&
                              collection.stage &&
                              collection.stage == "Deploy" ? (
                                <button
                                  className="text-white continueBtn"
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      currentStep: 4,
                                      collection: collection,
                                      folderName: folderName?.[2],
                                    });
                                    setSubMenuIndex(2);
                                    setContentMenu(true);
                                  }}
                                >
                                  Continue to {collection.stage}
                                </button>
                              ) : null}
                              {collection &&
                              collection.stage &&
                              collection.stage == "Collection Details" ? (
                                <button
                                  className="text-white continueBtn"
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      currentStep: 3,
                                      collection: collection,
                                      folderName: folderName?.[2],
                                    })
                                  }
                                >
                                  Continue to {collection.stage}
                                </button>
                              ) : null}
                              {collection &&
                              collection.stage &&
                              collection.stage == "Ready to Mint" ? (
                                <>
                                  <button
                                    className="text-white continueBtn"
                                    onClick={() =>
                                      history.push(
                                        `/${collection.nft_handle}/mint`
                                      )
                                    }
                                  >
                                    Continue to Mint
                                  </button>
                                </>
                              ) : null}
                              {collection &&
                              collection.stage &&
                              collection.stage == "Summary" ? (
                                <>
                                  <button
                                    className="text-white continueBtn"
                                    onClick={() =>
                                      setState({
                                        ...state,
                                        currentStep: 5,
                                        collection: collection,
                                        folderName: folderName?.[2],
                                      })
                                    }
                                  >
                                    Continue to Summary
                                  </button>
                                </>
                              ) : null}
                              {collection &&
                              collection.deployed_failed === true ? (
                                <button
                                  className="text-white continueBtn"
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      currentStep: 4,
                                      collection: collection,
                                      folderName: folderName?.[2],
                                    });
                                    setSubMenuIndex(2);
                                    setContentMenu(!contentMenu);
                                  }}
                                >
                                  Redeploy
                                </button>
                              ) : null}
                            </td>
                            <td className="text-white">
                              {collection?.updated_at &&
                                moment(collection.updated_at).format(
                                  "MMM D, YYYY - h:mm A"
                                )}
                            </td>
                          </tr>
                        );
                      })}
                  </Fragment>
                )}
              </tbody>
            </Table>
            <div className="p-25">
              <div className="rp-pagination">
                <div className="row">
                  <div className="col-md-6">
                    <div className="page-counter">
                      Page
                      <span className="page-numbers ms-2">
                        {page} of{" "}
                        {Math.ceil(Number(previousNfts.total) / limit)}
                      </span>
                      <div className="page-input">
                        <input
                          type={"text"}
                          value={page}
                          onChange={(e) => setPage(Number(e.target.value))}
                          placeholder="Page"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page-changer-right">
                      <div className="page-changer">
                        <button
                          className="prev me-2"
                          onClick={() => setPage(1)}
                          disabled={!previousNfts.prev}
                        >
                          {"<<"}
                        </button>
                        <button
                          className="me-2"
                          onClick={() => setPage(page - 1)}
                          disabled={!previousNfts.prev}
                        >
                          {"<"}
                        </button>
                        <span className="current-page">{page}</span>
                        <button
                          className="me-2"
                          onClick={() => setPage(page + 1)}
                          disabled={!previousNfts.next}
                        >
                          {">"}
                        </button>
                        <button
                          onClick={() =>
                            setPage(
                              Math.ceil(Number(previousNfts.total) / limit)
                            )
                          }
                          disabled={!previousNfts.next}
                        >
                          {">>"}
                        </button>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="flex-grow-1 ms-2">
                          <CommonSelect
                            items={[
                              { value: 10, label: "10" },
                              { value: 25, label: "25" },
                              { value: 50, label: "50" },
                              { value: 100, label: "100" },
                            ]}
                            value={limit || 10}
                            onChange={(v) => {
                              setPage(1);
                              setLimit(Number(v.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {withdrawModal && (
        <Modal
          show={withdrawModal}
          centered
          backdrop="static"
          onHide={() => {
            setIsWithdrawModal(false);
          }}
          className="preview-nft-metadata-model"
        >
          <Modal.Header>
            <Modal.Title className="text-center mx-auto">
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              Don't close window, withdrawal is under process, it can take few
              seconds.
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PreviousNFTs;

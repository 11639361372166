const ContentSpinner = () => {
  return (
    <div className="text-center mt-5 mb-5">
      <span className="spinner-border"></span>
      {/* <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}
    </div>
  );
};
export default ContentSpinner;

import { Suspense } from "react";
import { Spinner } from "react-bootstrap";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import MintEditPage from "./Mint/MintEditPage";
import MintedNFTS from "./Mint/MintedNFTs";
import MintPage from "./Mint/MintPage";
import NFTCreator from "./NFTCreator";
import NewNavbar from "./Navbar";
import WebLayout from "./Layout/WebLayout";
import HomePage from "./Homepage";

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <Switch>
          <WebRoute exact path="/" component={HomePage} />
          <WebRoute exact path="/nftcreator" component={NFTCreator} />
          <WebRoute exact path="/:nft_handle/mint" component={MintPage} />
          <WebRoute exact path="/:nft_handle/edit/:token_id" component={MintEditPage} />
          <WebRoute exact path="/minted-nfts" component={MintedNFTS} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

const WebRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <WebLayout>
          <Component {...props} />
        </WebLayout>
      )}
    />
  );
};

export default AppRoutes;

import { uploadFile, deleteFile } from "react-s3";
import { v4 as uuidv4 } from "uuid";
import * as mime from "mime-types";

const S3_BUCKET = "holygrails";
const REGION = "eu-central-1";
const ACCESS_KEY = "AKIA3SV7JX44DTHIYHVS";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const uploadToS3 = async (file, dir) => {
  config["dirName"] = dir;
  const ext = mime.extension(file.type);
  const _file = new File([file], `${uuidv4()}.${ext}`, { type: file.type });
  return new Promise((resolve, reject) => {
    uploadFile(_file, config)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

// const deleteFromS3 = async (file) => {
//   console.log('file', file);
//   return new Promise((resolve, reject) => {
//     console.log('********');
//     deleteFile(file, config)
//       .then((data) => resolve(data))
//       .catch((err) => reject(err));
//   });
// }
export { uploadToS3 };

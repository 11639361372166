import {toast} from "react-toastify";
import copy from 'copy-to-clipboard';

export const copyText = (text: string) => {
  copy(text);
  toast.success("Address Copied.",{ position: 'bottom-center'});
};

const COUNT_FORMATS = [
  {
    // 0 - 999
    letter: "",
    limit: 1e3,
  },
  {
    // 1,000 - 999,999
    letter: "K",
    limit: 1e6,
  },
  {
    // 1,000,000 - 999,999,999
    letter: "M",
    limit: 1e9,
  },
  {
    // 1,000,000,000 - 999,999,999,999
    letter: "B",
    limit: 1e12,
  },
  {
    // 1,000,000,000,000 - 999,999,999,999,999
    letter: "T",
    limit: 1e15,
  },
];

export const formatCount = (value: number) => {
  const format = COUNT_FORMATS.find((format) => value < format.limit);
  value = (1000 * value) / format!.limit;
  value = Math.round(value * 10) / 10;
  return value + format!.letter;
};

export const formatPrice = (value: number | string) => {
  return parseFloat(Number(value).toFixed(10));
};

export const makeSocialLinks = (
  link: string,
  type: "twitter" | "instagram" | "website"
) => {
  if (link.startsWith("http")) {
    return link;
  } else {
    switch (type) {
      case "twitter":
        return `https://twitter.com/${link}`;

      case "instagram":
        return `https://www.instagram.com/${link}/`;

      case "website":
        return `http://${link}/`;

      default:
        return link;
    }
  }
};

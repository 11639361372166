import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";

const HomePage = () => {
  const history = useHistory();
  return (
    <Fragment>
      <div className="nft-creator">
        <div
          className="container d-flex justify-content-center align-items-center"
          style={{
            maxWidth: "1400px",
            paddingTop: "190px",
            minHeight: "100vh",
          }}
        >
          <div className="home-page">
            <div className="title-text mb-4">
              From Traditional Crafts to smart Crafts by tokenising each item on
              chain.
            </div>
            <div className="details-text mb-5">
              NFT purchases that give the customers access to gated virtual and
              real life experiences and content.
            </div>
            <div className="mb-5">
              <div className="title-text mb-3">
                Try our e-commerce enabled NFT creator that allows you to offer:
              </div>
              <li className="details-text">Gated Purchase</li>
              <li className="details-text">Campaign Creation</li>
              <li className="details-text">Community Building</li>
              <li className="details-text">Virtual Spaces</li>
            </div>
            <div className="mt-4 d-flex">
              <button
                className="btnGray"
                onClick={() => history.push("/nftcreator")}
              >
                Create Your NFT
              </button>
              <button className="btnBlack ms-4">Watch Video</button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HomePage;

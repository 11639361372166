import { Fragment, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
} from "react-bootstrap";
import logo from "../assets/images/SuadiLogo.png";
import "./index.scss";
import { authContext } from "../Context/Web3AuthContext";
import { WalletContext } from "../Context/WalletContextProvider";
const NewNavbar = () => {
  const { address } = useContext(authContext);
  const { getWalletConnectDropDown, getWalletButtonBasedSelectedChain } =
    useContext(WalletContext);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [open, setOpen] = useState(true);

  return (
    <Navbar id="navbar-id" className="web-navbar" expand="xxl" expanded={open}>
      <div className="container navbar-main">
        <Navbar.Collapse id="basic-navbar-nav" className="navCollaspeMobile">
          <Nav className="align-items-start align-items-xxl-center menuOrder">
            <Navbar.Brand as={Link} to="/" className="navLogo">
              <img
                src={logo}
                alt=""
                width="250px"
                // height="18px"
                className="mr-0"
              />
            </Navbar.Brand>
            <Link
              to="/nftcreator"
              className={`nav-link ms-4	${
                splitLocation[1] === "NFT CREATOR" ? "active" : ""
              }`}
            >
              {/* <img src={StateIcon} alt="CollectionICon" /> */}
              <span>NFT CREATOR</span>
            </Link>
            <Link
              to="/"
              className={`nav-link ms-4	${
                splitLocation[1] === "MARKETPLACE" ? "active" : ""
              }`}
            >
              {/* <img src={StateIcon} alt="CollectionICon" /> */}
              <span>MARKETPLACE</span>
            </Link>
            <Link
              to="/"
              className={`nav-link ms-4	${
                splitLocation[1] === "SPACES" ? "active" : ""
              }`}
            >
              {/* <img src={StateIcon} alt="CollectionICon" /> */}
              <span>SPACES</span>
            </Link>
            <div className="d-flex">
              {getWalletConnectDropDown()}
              {getWalletButtonBasedSelectedChain()}
            </div>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NewNavbar;

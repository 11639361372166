import { Col, Container, Dropdown, Modal, Row, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { Fragment, useContext, useEffect, useState } from "react";
import UploadContent from "./UploadContent";
import PreviewNFTs from "./PreviewNFTs";
import NftSummary from "./NftSummary";
import SelectType from "./SelectType";
import "./index.scss";
import AxiosInstance from "../AxiosRequest";
import { toast, ToastContainer } from "react-toastify";
import PreviousNFTs from "./PreviousApplications";
import NFTDetails from "./NFTDetails";
import _ from "lodash";
import AboutCollection from "./AboutCollection";
import moment from "moment";
import { WalletContext } from "../Context/WalletContextProvider";
import { authContext } from "../Context/Web3AuthContext";
import ParentUpload from "./ParentUpload";
import { useHistory } from "react-router-dom";

const Schema = yup.object({
  type: yup.string().required("Please select a type"),
  projectName: yup.string().required("This field is required.").nullable(),
  noOfNfts: yup
    .number()
    .min(1, `Must be greter then or equal to 1`)
    .typeError("Must be a `number`")
    .required("This field is required.")
    .nullable(),
  noOfNftsPerUser: yup
    .number()
    .min(1, `Must be greter then or equal to 1`)
    .typeError("Must be a `number`")
    .required("This field is required.")
    .nullable(),
  nftSymbol: yup
    .string()
    .required("This field is required.")
    .min(3, "min 3 characters required")
    .matches(
      /^[a-zA-Z0-9_]{0,10}[a-zA-Z0-9_]*$/,
      "Please don't add special characters"
    )
    .max(10, "max 10 characters allowed")
    .nullable(),
  layers: yup
    .array()
    .when("type", {
      is: (type) => type === "layered",
      then: () =>
        yup
          .array()
          .of(
            yup.object().shape({
              layerName: yup.string().required("This field is required."),
              rarity: yup
                .number()
                .min(1, "Must be greater than 1")
                .max(100, "Must be less than 101")
                .required("This field is required.")
                .nullable(),
              images: yup
                .array()
                .of(
                  yup.object().shape({
                    image_name: yup
                      .string()
                      .required("This field is required.")
                      .nullable(),
                    image_file: yup
                      .string()
                      .required("This field is required.")
                      .nullable(),
                    image_url: yup
                      .string()
                      .required("This field is required.")
                      .nullable(),
                  })
                )
                .required("This is required")
                .min(1, "Upload at least 1 image"),
            })
          )
          .min(1, "Please add at least 1 layer"),
    })
    .nullable(),
  newLayer: yup.string(),
  price: yup
    .number()
    .min(0.001, "Must be greter then or equal to 0.001")
    .typeError("Must be a `number`")
    .required("This field is required.")
    .nullable(),
  creator_royalty: yup
    .number()
    .typeError("Must be a `number`")
    .min(0.01, "Must be greter then or equal to 0.01")
    .max(100, "Must be less then or equal to 100")
    .required("This field is required.")
    .nullable(),
  creator_email: yup
    .string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Please provide valid email"
    ),
});

export default function CreateNft({ state, setState }) {
  const { account, selectedChain } = useContext(WalletContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generatMsgModal, setGeneratMsgModal] = useState(false);
  const [lastSaveTime, setLastSaveTime] = useState();
  const [contentMenu, setContentMenu] = useState(false);
  const [subMenuIndex, setSubMenuIndex] = useState(0);
  const [imageGeneratedModal, setImageGeneratedModal] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [isLessLayersModal, setIsLessLayersModal] = useState(false);
  const { address, provider } = useContext(authContext);
  const [publicKey, setPublicKey] = useState(null);
  const [nfts, setNFTs] = useState([]);
  const history = useHistory();
  let intervalHandle = null;

  const formik = useFormik({
    initialValues: {
      type: "",
      projectName: "",
      noOfNfts: "",
      newLayer: "",
      noOfNftsPerUser: "",
      layers: [
        {
          layerName: "Background",
          rarity: 100,
          images: [],
        },
      ],
      nftSymbol: "",
      chooseCreator: "",
      price: "",
      description: "",
      creator_royalty: "",
      creator_email: "",
    },
    validationSchema: Schema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (publicKey || address) {
      getLastestTime();
      getMintedNFTs();
    }
  }, [publicKey, address, state.collection?._id, provider]);

  const getMintedNFTs = async () => {
    try {
      const response = await AxiosInstance.get("/nfts/fetchAllNfts");
      setNFTs(response.data.data);
    } catch (err) {
      throw err;
    }
  };

  const getLastestTime = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getLatestUpdatedCollection?creator=${
        selectedChain === "Solana"
          ? publicKey
            ? publicKey.toBase()
            : ""
          : address
      }`
    );
    if (response.status === 200) {
      if (response.data.data) {
        setLastSaveTime(response.data.data.updated_at);
      }
    }
  };

  useEffect(() => {
    if (
      state.collection?._id &&
      isSubmitting &&
      state.collection.type === "layered"
    )
      intervalHandle = setInterval(() => {
        getData();
      }, 10000);
  }, [state.collection?._id, isSubmitting]);

  const getData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getData?id=${state.collection._id}`
    );
    if (response.status === 200) {
      if (response.data.data.imagesGenerated) {
        clearInterval(intervalHandle);
        setGeneratMsgModal(false);
        setImageGeneratedModal(true);
        setIsSubmitting(false);
        // setSubMenuIndex(1);
      } else if (response.data.data.less_layers) {
        clearInterval(intervalHandle);
        setGeneratMsgModal(false);
        setIsLessLayersModal(true);
        setIsSubmitting(false);
      }
    }
  };

  //This function will called when user click on generate nfts
  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (state.collection.type === "layered") {
      setGeneratMsgModal(true);
    }

    const formData = new FormData();
    let attributes = [];

    // create formdata for generate images.
    if (state.collection.layers?.[0]?.images?.length > 0) {
      for (let i = 0; i < state.collection.layers.length; i++) {
        for (let j = 0; j < state.collection.layers[i].images.length; j++) {
          if (state.collection.layers?.[i]?.images?.[j]?.name) {
            const file = state.collection.layers[i].images[j];
            const ext = file?.type.split("/");
            state.collection.layers[i].images[j].image_file = file;
            state.collection.layers[i].images[
              j
            ].image_name = `${state.collection.layers[i].attributes[j].background_color}#${state.collection.layers[i].attributes[j].rarity}_${state.collection.layers[i].layerName}.${ext[1]}`;
          } else {
            const file = state.collection.layers[i].images[j].image_file;
            const ext = file?.type.split("/");
            // state.collection.layers[i].images[j].image_file = file;
            state.collection.layers[i].images[
              j
            ].image_name = `${state.collection.layers[i].images[j].background_color}#${state.collection.layers[i].images[j].rarity}_${state.collection.layers[i].layerName}.${ext[1]}`;
          }
          formData.append(
            `images`,
            state.collection.layers[i].images[j].image_file,
            state.collection.layers[i].images[j].image_name
          );
        }
      }
    } else {
      for (let i = 0; i < formik.values.layers.length; i++) {
        for (let j = 0; j < formik.values.layers[i].images.length; j++) {
          formData.append(
            `images`,
            formik.values.layers[i].images[j].image_file,
            formik.values.layers[i].images[j].image_name
          );
        }
      }
    }

    for (let i = 0; i < state.collection.layers.length; i++) {
      if (state.collection.layers[i]?.images?.length > 0) {
        let attributes = [];
        for (let j = 0; j < state.collection.layers[i].images.length; j++) {
          if (
            state.collection.layers?.[i]?.attributes?.[j]?.background_color &&
            state.collection.layers?.[i]?.attributes?.[j]?.rarity
          ) {
            attributes.push({
              background_color:
                state.collection.layers[i].attributes[j].background_color,
              rarity: state.collection.layers[i].attributes[j].rarity,
            });
          } else {
            attributes.push({
              background_color:
                state.collection.layers[i].images[j].background_color,
              rarity: state.collection.layers[i].images[j].rarity,
            });
          }
        }
        state.collection.layers[i].attributes = attributes;
      }
    }

    for (let i = 0; i < formik.values.layers.length; i++) {
      if (formik.values.layers[i].images?.length > 0) {
        let attributes = [];
        for (let j = 0; j < formik.values.layers[i].images.length; j++) {
          attributes.push({
            background_color:
              formik.values.layers[i].images[j].background_color,
            rarity: formik.values.layers[i].images[j].rarity,
          });
        }
        formik.values.layers[i].attributes = attributes;
      }
    }

    formData.append(
      "creator_email",
      state.collection?.creator_email
        ? state.collection.creator_email
        : formik.values.creator_email
    );
    formData.append(
      "collection_name",
      state.collection?.collection_name
        ? state.collection.collection_name
        : formik.values.projectName
    );
    formData.append(
      "price_per_nft",
      state.collection?.price_per_nft
        ? state.collection.price_per_nft
        : formik.values.price
    );
    formData.append(
      "no_of_nfts",
      state.collection?.no_of_nfts
        ? state.collection.no_of_nfts
        : formik.values.noOfNfts
    );
    formData.append(
      "nft_handle",
      state.collection?.nft_handle
        ? state.collection.nft_handle
        : formik.values.nftSymbol
    );
    formData.append(
      "creator_royalty",
      state.collection?.creator_royalty
        ? state.collection.creator_royalty
        : formik.values.creator_royalty
    );
    formData.append("description", formik.values.description);
    const folderName =
      state.collection?.folderName && state.collection.folderName;

    if (state.collection?.layers?.[0]?.images?.length > 0) {
      const layer = _.map(state.collection.layers, function (item) {
        return _.omit(item, ["images"]);
      });
      formData.append("layers", JSON.stringify(layer));
    } else {
      const layer = _.map(formik.values.layers, function (item) {
        return _.omit(item, ["images"]);
      });
      formData.append("layers", JSON.stringify(layer));
    }

    if (state.collection.type !== "layered") {
      formData.append("images", formik.values.zipfile, "0.zip");
    }

    if (state.collection?.stage === "Deposit") {
      const response = await AxiosInstance.post(
        `/nftcreator/regenerate?creator=${
          selectedChain === "Solana"
            ? publicKey
              ? publicKey.toBase()
              : ""
            : address
        }&id=${state.collection._id}&folderName=${folderName}&type=${
          state.collection.type
        }`,
        formData
      );
      if (response.status === 200) {
        if (response.data && response.data.data) {
          const folder = response.data.data.folderName;
          const folderName = folder?.split("/");
          setState({
            ...state,
            collection: response.data.data,
            folderName: folderName[2],
            currentStep: 4,
          });
          if (state.collection.type !== "layered") {
            setSubMenuIndex(1);
            setIsSubmitting(false);
          }
        } else {
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
        setGeneratMsgModal(false);
      }
    } else {
      const response = await AxiosInstance.post(
        `/nftcreator/create?creator=${
          selectedChain === "Solana"
            ? publicKey
              ? publicKey.toBase()
              : ""
            : address
        }&id=${state.collection._id}&folderName=${folderName}&type=${
          state.collection.type
        }`,
        formData
      );
      if (response.status === 200) {
        if (response.data && response.data.data) {
          const folder = response.data.data.folderName;
          const folderName = folder?.split("/");
          setState({
            ...state,
            collection: response.data.data,
            folderName: folderName[2],
            currentStep: 4,
          });
          if (state.collection.type !== "layered") {
            setSubMenuIndex(1);
            setIsSubmitting(false);
          }
        } else {
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
        setGeneratMsgModal(false);
      }
    }
  };

  const previous = () => {
    setState({
      ...state,
      currentStep: state.currentStep - 1,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const next = () => {
    setState({
      ...state,
      currentStep: state.currentStep + 1,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const customStep = (step) => {
    setState({
      ...state,
      currentStep: step,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const stepContent = {
    1: {
      title: "Select Type",
      component: <SelectType next={next} formik={formik} setState={setState} />,
    },
    2: {
      title: "",
      component: (
        <NFTDetails
          state={state}
          setState={setState}
          formik={formik}
          publicKey={
            selectedChain === "Solana"
              ? publicKey
                ? publicKey.toBase()
                : ""
              : address
          }
        />
      ),
    },
    3: {
      title: "",
      component: (
        <AboutCollection
          state={state}
          next={next}
          setState={setState}
          publicKey={
            selectedChain === "Solana"
              ? publicKey
                ? publicKey.toBase()
                : ""
              : address
          }
          setSubMenuIndex={setSubMenuIndex}
          setContentMenu={setContentMenu}
          contentMenu={contentMenu}
        />
      ),
    },
    4: [
      {
        title: "",
        component: (
          <ParentUpload
            formik={formik}
            state={state}
            setState={setState}
            imageFile={imageFile}
            setImageFile={setImageFile}
            subMenuIndex={subMenuIndex}
            contentMenu={contentMenu}
          />
        ),
      },
      {
        title: "",
        component: (
          <PreviewNFTs
            formik={formik}
            state={state}
            previous={previous}
            next={next}
            setState={setState}
            setSubMenuIndex={setSubMenuIndex}
            contentMenu={contentMenu}
            setContentMenu={setContentMenu}
            subMenuIndex={subMenuIndex}
            setImageFile={setImageFile}
            publicKey={
              selectedChain === "Solana"
                ? publicKey
                  ? publicKey.toBase()
                  : ""
                : address
            }
          />
        ),
      },
      {
        title: "",
        component: (
          <PreviewNFTs
            formik={formik}
            state={state}
            previous={previous}
            next={next}
            setState={setState}
            setSubMenuIndex={setSubMenuIndex}
            contentMenu={contentMenu}
            setContentMenu={setContentMenu}
            subMenuIndex={subMenuIndex}
            setImageFile={setImageFile}
            publicKey={
              selectedChain === "Solana"
                ? publicKey
                  ? publicKey.toBase()
                  : ""
                : address
            }
          />
        ),
      },
    ],
    // 4: {
    //   title: "",
    //   component: <Deployment state={state} next={next} />,
    // },

    5: {
      title: "Summary",
      component: <NftSummary formik={formik} state={state} />,
    },
    6: {
      title: "",
      component: (
        <PreviousNFTs
          state={state}
          setState={setState}
          publicKey={
            selectedChain === "Solana"
              ? publicKey
                ? publicKey.toBase()
                : ""
              : address
          }
          setSubMenuIndex={setSubMenuIndex}
          contentMenu={contentMenu}
          setContentMenu={setContentMenu}
        />
      ),
    },
  };

  //This is called when user click on save as draft
  const updateStage = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      let attributes = [];
      // setIsSaveAsDraft(true);
      if (state.collection.layers?.[0]?.images?.length > 0) {
        for (let i = 0; i < state.collection.layers.length; i++) {
          for (let j = 0; j < state.collection.layers[i].images.length; j++) {
            if (state.collection.layers?.[i]?.images?.[j]?.name) {
              const file = state.collection.layers[i].images[j];
              const ext = file?.type.split("/");
              state.collection.layers[i].images[j].image_file = file;
              state.collection.layers[i].images[
                j
              ].image_name = `${state.collection.layers[i].attributes[j].background_color}#${state.collection.layers[i].attributes[j].rarity}_${state.collection.layers[i].layerName}.${ext[1]}`;
            } else {
              const file = state.collection.layers[i].images[j].image_file;
              const ext = file?.type.split("/");
              // state.collection.layers[i].images[j].image_file = file;
              state.collection.layers[i].images[
                j
              ].image_name = `${state.collection.layers[i].images[j].background_color}#${state.collection.layers[i].images[j].rarity}_${state.collection.layers[i].layerName}.${ext[1]}`;
            }
            formData.append(
              `images`,
              state.collection.layers[i].images[j].image_file,
              state.collection.layers[i].images[j].image_name
            );
          }
        }
      } else {
        for (let i = 0; i < formik.values.layers.length; i++) {
          for (let j = 0; j < formik.values.layers[i].images.length; j++) {
            formData.append(
              `images`,
              formik.values.layers[i].images[j].image_file,
              formik.values.layers[i].images[j].image_name
            );
          }
        }
      }
      // }
      for (let i = 0; i < state.collection.layers.length; i++) {
        if (state.collection.layers[i]?.images?.length > 0) {
          let attributes = [];
          for (let j = 0; j < state.collection.layers[i].images.length; j++) {
            if (
              state.collection.layers?.[i]?.attributes?.[j]?.background_color &&
              state.collection.layers?.[i]?.attributes?.[j]?.rarity
            ) {
              attributes.push({
                background_color:
                  state.collection.layers[i].attributes[j].background_color,
                rarity: state.collection.layers[i].attributes[j].rarity,
              });
            } else {
              attributes.push({
                background_color:
                  state.collection.layers[i].images[j].background_color,
                rarity: state.collection.layers[i].images[j].rarity,
              });
            }
          }
          state.collection.layers[i].attributes = attributes;
        }
      }

      for (let i = 0; i < formik.values.layers.length; i++) {
        if (formik.values.layers[i].images?.length > 0) {
          let attributes = [];
          for (let j = 0; j < formik.values.layers[i].images.length; j++) {
            attributes.push({
              background_color:
                formik.values.layers[i].images[j].background_color,
              rarity: formik.values.layers[i].images[j].rarity,
            });
          }
          formik.values.layers[i].attributes = attributes;
        }
      }

      formData.append(
        "creator_email",
        state.collection?.creator_email
          ? state.collection.creator_email
          : formik.values.creator_email
      );
      formData.append(
        "collection_name",
        state.collection?.collection_name
          ? state.collection.collection_name
          : formik.values.projectName
      );
      formData.append(
        "price_per_nft",
        state.collection?.price_per_nft
          ? state.collection.price_per_nft
          : formik.values.price
      );
      formData.append(
        "no_of_nfts",
        state.collection?.no_of_nfts
          ? state.collection.no_of_nfts
          : formik.values.noOfNfts
      );
      formData.append(
        "nft_handle",
        state.collection?.nft_handle
          ? state.collection.nft_handle
          : formik.values.nftSymbol
      );
      formData.append("description", formik.values.description);

      formData.append(
        "creator_royalty",
        state.collection?.creator_royalty
          ? state.collection.creator_royalty
          : formik.values.creator_royalty
      );
      if (state.collection?.layers?.[0]?.images?.length > 0) {
        const layer = _.map(state.collection.layers, function (item) {
          return _.omit(item, ["images"]);
        });
        formData.append("layers", JSON.stringify(layer));
      } else {
        const layer = _.map(formik.values.layers, function (item) {
          return _.omit(item, ["images"]);
        });
        formData.append("layers", JSON.stringify(layer));
      }

      // Updates when user selects video, audios or documents
      if (state.collection.type !== "layered") {
        formData.append("images", formik.values.zipfile, "0.zip");
      }

      const stage = "Traits";
      const folderName =
        state.collection?.folderName && state.collection.folderName;
      const response = await AxiosInstance.post(
        `/nftcreator/saveAsDraft?id=${
          state.collection._id
        }&stage=${stage}&creator=${
          selectedChain === "Solana"
            ? publicKey
              ? publicKey.toBase()
              : ""
            : address
        }&folderName=${folderName}&type=${state.collection.type}`,
        formData
      );
      if (response.status === 200) {
        if (response.data && response.data.data) {
          const folder = response.data.data.folderName;
          const folderName = folder?.split("/");
          setState({
            ...state,
            collection: response.data.data,
            folderName: folderName[2],
            currentStep: 6,
          });
          setImageFile([]);
          setIsLoading(false);
          setContentMenu(!contentMenu);
          toast.success("Save as draft successfully");
        }
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      {state.currentStep !== 0 && (
        <Row className="">
          <Col
            xl={2}
            lg={2}
            md={2}
            className="mb-3"
            style={{ minHeight: "625px" }}
          >
            <div
              className="leftpart mb-5"
              style={{ top: `${contentMenu ? "175px" : "195px"}` }}
            >
              <ul className="p-0 list-unstyled">
                <li
                  className={state.currentStep === 1 ? "active" : null}
                  onClick={() => {
                    customStep(1);
                    if (contentMenu) {
                      setContentMenu(false);
                    }
                  }}
                >
                  Select Type
                </li>
                <li
                  className={state.currentStep === 2 ? "active" : null}
                  onClick={() => {
                    if (formik.values.type !== "") {
                      if (!state.collection?.imagesGenerated) {
                        customStep(2);
                        if (contentMenu) {
                          setContentMenu(false);
                        }
                      } else if (subMenuIndex === 0) {
                        customStep(2);
                        if (contentMenu) {
                          setContentMenu(false);
                        }
                      }
                    }
                  }}
                >
                  NFT Details
                </li>
                <li
                  className={state.currentStep === 3 ? "active" : null}
                  onClick={() => {
                    if (state.collection?._id) {
                      customStep(3);
                      if (contentMenu) {
                        setContentMenu(false);
                      }
                    }
                  }}
                >
                  Collection Info
                </li>
                <li className={state.currentStep === 4 ? "active" : null}>
                  <div
                    className={state.currentStep === 4 ? "active" : null}
                    onClick={() => {
                      if (state.currentStep === 4 || contentMenu) {
                        setContentMenu(!contentMenu);
                      }
                    }}
                  >
                    NFT Generator
                  </div>
                  <ul
                    className="mt-2 ms-4 list-unstyled"
                    style={
                      contentMenu ? { display: "block" } : { display: "none" }
                    }
                  >
                    <li
                      className={
                        subMenuIndex === 0 ? "activeMenu" : "disableMenu"
                      }
                    >
                      Upload Content
                    </li>
                    <li
                      className={
                        subMenuIndex === 1 ? "activeMenu" : "disableMenu"
                      }
                    >
                      Generate NFTS
                    </li>
                    <li
                      className={
                        subMenuIndex === 2 ? "activeMenu" : "disableMenu"
                      }
                    >
                      Deploy
                    </li>
                  </ul>
                </li>
                <li
                  className={state.currentStep === 5 ? "active" : null}
                  style={{ paddingBottom: "15px" }}
                >
                  Summary
                </li>
              </ul>
            </div>
            {lastSaveTime && (
              <div
                className="saveasdraft"
                style={{ top: `${contentMenu ? "580px" : "520px"}` }}
              >
                <div className="mb-3 draft-text">You already have a draft!</div>
                {lastSaveTime && (
                  <span className="save-time">
                    {moment(lastSaveTime).format("MMM D, YYYY - h:mm A")}
                  </span>
                )}
                <div className="mt-2 mb-3">
                  <button
                    className="btnGray"
                    style={{
                      marginLeft: "26px",
                      marginRight: "26px",
                      width: "143px",
                    }}
                    onClick={() => customStep(6)}
                  >
                    Resume Draft
                  </button>
                </div>
              </div>
            )}
            {nfts.length > 0 && (
              <div
                className="mint-nfts"
                style={{
                  top: `${
                    lastSaveTime && contentMenu
                      ? "800px"
                      : lastSaveTime && !contentMenu
                      ? "740px"
                      : contentMenu
                      ? "550px"
                      : "490px"
                  }`,
                }}
              >
                <div className="mt-3 mb-3">
                  <button
                    className="btnGray"
                    style={{
                      marginLeft: "26px",
                      marginRight: "26px",
                      marginTop: "18px",
                      width: "143px",
                    }}
                    onClick={() => history.push("/minted-nfts")}
                  >
                    Minted NFTS
                  </button>
                </div>
              </div>
            )}
          </Col>

          <Col xl={10} lg={10} md={10}>
            <div className="rightpart me-5">
              <div className="d-flex justify-content-between">
                <h3 className="title-text">
                  {state.currentStep === 4
                    ? stepContent[state.currentStep]?.[subMenuIndex]?.title
                    : stepContent[state.currentStep]?.title}
                </h3>
              </div>
            </div>
            {state.currentStep === 4
              ? stepContent[state.currentStep]?.[subMenuIndex]?.component
              : stepContent[state.currentStep].component}
          </Col>
          {state.currentStep === 4 && subMenuIndex === 0 && (
            <div className="nftGeneratorFooter">
              <div
                className=""
                style={{
                  width: "100%",
                  border: "1px solid #FFFFFF2B",
                  opacity: "1",
                }}
              ></div>
              <div className="d-flex justify-content-between p-4 mb-4">
                <div className="date-text">
                  © 2023 NFTCreator - All Rights Reserved
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="saveasdraftBtn"
                    style={{
                      width: "196px",
                      padding: "0",
                      fontWeight: "500",
                      borderRadius: "10px",
                      height: "44px",
                      fontSize: "18px",
                    }}
                    onClick={updateStage}
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-1"
                      />
                    )}
                    Save as draft
                  </button>
                  <button
                    type="button"
                    className="ms-4 btnGray"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    style={{ width: "196px", padding: "0", fontSize: "18px" }}
                  >
                    {isSubmitting && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-1"
                      />
                    )}
                    {state.collection.type === "layered" ? "Generate" : "Next"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Row>
      )}
      {generatMsgModal && (
        <Modal
          show={generatMsgModal}
          centered
          backdrop="static"
          onHide={() => {
            setGeneratMsgModal(false);
          }}
          className="preview-nft-metadata-model"
        >
          <Modal.Header>
            <Modal.Title className="text-center mx-auto">
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              Please be patient and don't close the window. ☕️
            </div>
            <div className="mb-3">
              NFT image generation is currently in progress and may take a few
              minutes. Thank you for your understanding.
            </div>
          </Modal.Body>
        </Modal>
      )}
      {imageGeneratedModal && (
        <Modal
          show={imageGeneratedModal}
          centered
          backdrop="static"
          onHide={() => {
            setImageGeneratedModal(false);
          }}
          className="preview-nft-metadata-model"
        >
          <Modal.Header>
            <Modal.Title className="text-center mx-auto">
              NFT Generated Successfully
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              Congratulations 🥳 your NFT’s are generated successfully!
            </div>
            <div className="mb-3">
              Now you can move on to next step and you can publish your
              collection to the world.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btnGray"
              onClick={() => {
                setImageGeneratedModal(false);
                setSubMenuIndex(1);
              }}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {isLessLayersModal && (
        <Modal
          show={isLessLayersModal}
          centered
          backdrop="static"
          onHide={() => {
            setIsLessLayersModal(false);
          }}
          className="preview-nft-metadata-model"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center mx-auto"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">😮 Uh-oh!</div>
            <div className="mb-3">
              It seems like you need to add more layers to generate.
            </div>
            <div className="mb-3">
              Solution: To fix this issue, you can try adding more images or
              reducing the maximum number of NFTs you want to generate. We're
              confident you can resolve this quickly!
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Confetti from "react-confetti";
import AxiosInstance from "../AxiosRequest";
import { DeviceContext } from "../Context/DeviceContext";
import { WalletContext } from "../Context/WalletContextProvider";
import { authContext } from "../Context/Web3AuthContext";
import collectionicon9 from "../assets/images/socialIcon/twitter.svg";
import collectionicon10 from "../assets/images/socialIcon/discord.svg";
import placholderImage from "../assets/images/placeholder1.png";
import websiteLogo from "../assets/images/socialIcon/collection_website.svg";
import { useHistory, useParams } from "react-router-dom";
import Web3 from "web3";
import Nft from "../contract/Nft.json";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const MintPage = () => {
  const [collectionDetails, setCollectionDetails] = useState();
  const { device } = useContext(DeviceContext);
  const [nftMinted, setNFTMinted] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [collectionImage, setCollectionImage] = useState();
  const [isConfetti, setConfetti] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHight] = useState(window.innerHeight);
  const { address, provider } = useContext(authContext);
  const params = useParams();
  const { getWalletConnectDropDown, getWalletButtonBasedSelectedChain } =
    useContext(WalletContext);
  const [isEdit, setIsEdit] = useState(false);
  const history = useHistory();
  const [nftMintedPerUser, setNFTMintedPerUser] = useState();

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getDataBySymbol?nft_handle=${params.nft_handle}`
    );
    if (response.status === 200) {
      let folder = response.data.data.folderName;
      if (response.data && response.data.data) {
        if (response.data.data?.collection_image) {
          setCollectionImage(response.data.data.collection_image);
        }
        setCollectionDetails(response.data.data);
        if (response.data.data?.minted_nfts) {
          setNFTMinted(response.data.data.minted_nfts);
        }
        // const cndy = await getCandyMachineState(
        //   anchorWallet,
        //   new anchor.web3.PublicKey(response.data.data.candyMachineId),
        //   connection
        // );
        // setNFTMinted(cndy.state.itemsRedeemed);
      }
    }
  };

  useEffect(() => {
    if (params?.nft_handle) {
      getData();
    }
  }, [params]);

  useEffect(() => {
    if (address && params?.nft_handle) {
      getNFTByWalletAddress();
    }
  }, [nftMinted, address, params]);

  const getNFTByWalletAddress = async () => {
    try {
      const response = await AxiosInstance.post("/nfts/nftsByWalletAddress", {
        address: address,
        nft_handle: params.nft_handle,
      });
      setNFTMintedPerUser(response.data.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const mintNFT = async () => {
    try {
      setIsLoading(true);
      const web3 = new Web3(window.ethereum);
      const nftContract = new web3.eth.Contract(
        Nft.abi,
        collectionDetails.contract_address
      );
      const tx = await nftContract.methods.mint(1).send({
        from: address,
        value: web3.utils.toWei(
          collectionDetails.price_per_nft.toString(),
          "ether"
        ),
      });
      let user, nftId, URI;
      await web3.eth.getTransactionReceipt(
        tx.transactionHash,
        (error, receipt) => {
          const eventAbi = Nft.abi.find((abi) => abi.name === "Mint");
          const mintEvent = receipt.logs.find(
            (log) => log.topics[0] === eventAbi.signature
          );
          const decodedData = web3.eth.abi.decodeLog(
            eventAbi.inputs,
            mintEvent.data,
            mintEvent.topics.slice(1)
          );
          user = decodedData.user;
          nftId = decodedData.tokenId;
          URI = decodedData.URI;
        }
      );
      await AxiosInstance.post("nftcreator/updateStage", {
        _id: collectionDetails._id,
        contract_address: collectionDetails.contract_address,
        minted_nfts: Number(nftId) + 1,
        uri: collectionDetails.uri,
        stage: "Ready to Mint",
      });
      await AxiosInstance.post("/users/mintednfts", {
        wallet_address: user,
        token_id: Number(nftId),
        nft_handle: collectionDetails.nft_handle,
      });
      const metadata = await axios.get(
        `${process.env.REACT_APP_API_URL}/${
          collectionDetails.folderName.split("/")[2]
        }/assets/${Number(nftId)}.json`
      );
      await AxiosInstance.post("nfts/create", {
        owner_publickey: user,
        token_id: Number(nftId),
        nft_handle: collectionDetails.nft_handle,
        attributes: metadata.data?.attributes,
        name: metadata.data.name,
        image_url: `${process.env.REACT_APP_API_URL}/${
          collectionDetails.folderName.split("/")[2]
        }/assets/${Number(nftId)}.png`,
        metadata_url: URI,
        type: collectionDetails.type,
      });
      setNFTMinted(Number(nftId) + 1);
      setTimeout(() => {
        setConfetti(true);
      }, 300);
      toast.success("NFT Minted successfully.");
      setTimeout(() => {
        setConfetti(false);
      }, 15000);
      setIsLoading(false);
    } catch (err) {
      setConfetti(false);
      setIsLoading(false);
      console.log(err);
      throw err;
    }
  };

  return (
    <div className="nft-creator">
      <ToastContainer />
      {isConfetti && <Confetti width={width} height={height} />}
      {!address ? (
        <div
          className="mx-5 d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          {getWalletConnectDropDown()}
          {getWalletButtonBasedSelectedChain()}
        </div>
      ) : (
        <div
          style={{
            maxWidth: "14000px",
            paddingBottom: "10px",
            paddingTop: "120px",
            minHeight: "100vh",
          }}
        >
          <div className="container grayBox mt-5">
            <div className="collection-details">
              {device === "mobile" ? (
                <div className="nft-name text-center mb-2">
                  {collectionDetails && collectionDetails.collection_name}
                </div>
              ) : null}
              <Row>
                <Col
                  xl={4}
                  lg={4}
                  sm={12}
                  className="d-flex justify-content-end"
                >
                  <img
                    className="mint-nftimage"
                    src={collectionImage ? collectionImage : placholderImage}
                    alt=""
                  />
                </Col>
                <Col xl={7} lg={7} sm={12} className="ms-4">
                  <div className="mb-4">
                    <div className="d-flex collection-description-details">
                      <div>
                        {device !== "mobile" && (
                          <div className="nft-name mb-1">
                            {collectionDetails &&
                              collectionDetails.collection_name}
                          </div>
                        )}
                        <div className="label-name">
                          {collectionDetails && collectionDetails.description}
                        </div>
                        <div className="d-flex flex-wrap mt-2 mb-2">
                          {collectionDetails &&
                            collectionDetails.twitter_url && (
                              <a
                                href={`https://twitter.com/${collectionDetails.twitter_url}`}
                                rel="noreferrer"
                                target={"_blank"}
                                style={{ textDecoration: "none" }}
                              >
                                <span
                                  className={`mb-2 me-3 align-items-center image1`}
                                >
                                  <img
                                    src={collectionicon9}
                                    alt="twitter"
                                    className=""
                                  />
                                </span>
                              </a>
                            )}
                          {collectionDetails &&
                            collectionDetails.discord_url && (
                              <a
                                href={`${collectionDetails.discord_url}`}
                                target={"_blank"}
                              >
                                <span className="image1 mb-2 me-3">
                                  <img
                                    src={collectionicon10}
                                    alt="discord"
                                    className=""
                                  />
                                </span>
                              </a>
                            )}
                          {collectionDetails && collectionDetails.website && (
                            <a
                              href={collectionDetails.website}
                              target={"_blank"}
                            >
                              <span className="image1 mb-2 me-3">
                                <img
                                  src={websiteLogo}
                                  alt="website"
                                  // className=""
                                />
                              </span>
                            </a>
                          )}
                        </div>
                        {collectionDetails && collectionDetails.whitepaper && (
                          <div className="">
                            <a
                              className="textDetails"
                              href={`${collectionDetails.whitepaper}`}
                              target={"_blank"}
                            >
                              Whitepaper
                            </a>
                          </div>
                        )}
                      </div>
                      {device !== "mobile" && (
                        <div
                          className="hr mx-4"
                          style={{ maxHeight: "180px" }}
                        ></div>
                      )}
                      <div className="total-items">
                        <div className="label-name mb-1 mt-2">Total Items</div>
                        <div className="deploy-card-title">
                          {collectionDetails && collectionDetails.no_of_nfts}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-5 total-minted">
                    <div className="mb-2 label-name">Total Minted</div>
                    <div className="d-flex justify-content-between mb-1">
                      <div
                        className="deploy-card-status"
                        style={{ color: "#FFFFFF" }}
                      >
                        {Number(
                          (nftMinted * 100) / collectionDetails?.no_of_nfts
                        ).toFixed(2)}
                        %
                      </div>
                      <div
                        className="deploy-card-status"
                        style={{ color: "#FFFFFF" }}
                      >
                        ({nftMinted} /{" "}
                        {collectionDetails && collectionDetails.no_of_nfts})
                      </div>
                    </div>
                    <div className="progress mb-3">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${
                            (nftMinted * 100) / collectionDetails?.no_of_nfts
                          }%`,
                        }}
                      ></div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>
                        <div className="label-name">Price</div>
                        <div className="header-title">
                          ◎
                          {collectionDetails ? (
                            String(collectionDetails.price_per_nft).includes(
                              "-"
                            ) ? (
                              parseFloat(
                                collectionDetails.price_per_nft
                              ).toFixed(
                                String(collectionDetails.price_per_nft).split(
                                  "-"
                                )[1]
                              )
                            ) : (
                              parseFloat(collectionDetails.price_per_nft)
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          {collectionDetails &&
                          collectionDetails.no_of_nfts === nftMinted ? (
                            <span className="label-name">
                              All NFT's are minted
                            </span>
                          ) : collectionDetails &&
                            collectionDetails.no_of_nfts_per_user ===
                              nftMintedPerUser ? (
                            <span className="label-name">
                              You have reached your limit
                            </span>
                          ) : (
                            <button
                              className="btnGray ms-2"
                              onClick={() => mintNFT()}
                              disabled={isLoading}
                            >
                              {isLoading && (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className="me-1"
                                />
                              )}
                              Mint
                            </button>
                          )}
                        </div>
                        {nftMinted > 0 && (
                          <button
                            className="btnGray ms-4"
                            onClick={() => history.push("/minted-nfts")}
                          >
                            Explore Collection
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end"></div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="p-3">
              {collectionDetails && collectionDetails.about && (
                <>
                  <div className="mb-5 about-details">About</div>
                  <div className="mb-4 label-name">
                    {collectionDetails.about
                      .split("\n")
                      .map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MintPage;

import axios from "axios";
import JSZip from "jszip";
import Web3 from "web3";
import Nft from "../contract/Nft.json";
import {
  REACT_APP_PINATA_API_KEY,
  REACT_APP_PINATA_API_SECRET,
  REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
} from "../constants";
import {toast,ToastContainer} from "react-toastify";
import AxiosInstance from "../AxiosRequest";

const headers = {
  pinata_api_key: REACT_APP_PINATA_API_KEY,
  pinata_secret_api_key: REACT_APP_PINATA_API_SECRET,
  "Content-Type": "multipart/form-data",
};

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {
    type: mime,
  });
};

const jsontoFile = (data, filename) => {
  var dataurl = `data:application/json;base64,${btoa(JSON.stringify(data))}`;
  return dataURLtoFile(dataurl, filename);
};

function getFileNameFromPath(filePath) {
  const pathArray = filePath.split("/");
  return pathArray[pathArray.length - 1];
}

export const videoExtractutil = async (archive) => {
  const videos = [];
  const fileNames = [];
  archive.forEach(async (relativePath, zipEntry) => {
    console.log("zipEntry.name = ", zipEntry.name);
    if (
      !zipEntry.name.startsWith("__MACOSX/") &&
      (zipEntry.name.endsWith(".mp4") ||
        zipEntry.name.endsWith(".avi") ||
        zipEntry.name.endsWith(".mov"))
    ) {
      const fileName = getFileNameFromPath(zipEntry.name);
      fileNames.push(fileName);
      const videoBlob = zipEntry.async("blob");
      videos.push(videoBlob);
    }
  });

  return { videos, fileNames };
};

const audioExtractutil = async (archive) => {
  const audios = [];
  const fileNames = [];
  archive.forEach(async (relativePath, zipEntry) => {
    console.log("zipEntry.name = ", zipEntry.name);
    if (
      !zipEntry.name.startsWith("__MACOSX/") &&
      (zipEntry.name.endsWith(".mp3") ||
        zipEntry.name.endsWith(".wav") ||
        zipEntry.name.endsWith(".ogg"))
    ) {
      const fileName = getFileNameFromPath(zipEntry.name);
      fileNames.push(fileName);
      const audioBlob = zipEntry.async("blob");
      audios.push(audioBlob);
    }
  });

  return { audios, fileNames };
};

const documentExtractutil = async (archive) => {
  const documents = [];
  const fileNames = [];
  archive.forEach(async (relativePath, zipEntry) => {
    if (
      !zipEntry.name.startsWith("__MACOSX/") &&
      (zipEntry.name.endsWith(".md") ||
        zipEntry.name.endsWith(".txt") ||
        zipEntry.name.endsWith(".pdf") ||
        zipEntry.name.endsWith(".html") ||
        zipEntry.name.endsWith(".doc") ||
        zipEntry.name.endsWith(".docx") ||
        zipEntry.name.endsWith(".odt") ||
        zipEntry.name.endsWith(".rtf"))
    ) {
      const fileName = getFileNameFromPath(zipEntry.name);
      fileNames.push(fileName);
      const documentBlob = zipEntry.async("blob");
      documents.push(documentBlob);
    }
  });

  return { documents, fileNames };
};

export const uploadVideosFromBlob = async (
  finalVideosData,
  collection,
  videosAll
) => {
  try {
    let imageUrlArr = [];
    const iFormData = new FormData();

    for (let i = 0; i < finalVideosData.length; i++) {
      iFormData.append(
        "file",
        finalVideosData[i],
        `${collection.collection_name}_Assets/${
          videosAll.fileNames[i].split(".")[0]
        }`
      );
    }

    const iResponse = await axios({
      method: "post",
      url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
      data: iFormData,
      headers,
    });

    console.log("iResponseData", iResponse);

    for (let i = 0; i < finalVideosData.length; i++) {
      console.log(
        "video link",
        `https://gateway.pinata.cloud/ipfs/${iResponse.data.IpfsHash}/${
          videosAll.fileNames[i].split(".")[0]
        }`
      );
      imageUrlArr.push(
        `https://gateway.pinata.cloud/ipfs/${iResponse.data.IpfsHash}/${
          videosAll.fileNames[i].split(".")[0]
        }`
      );
    }

    return imageUrlArr;
  } catch (err) {
    console.log("err", err);
  }
};

export const extractVideoFromZip = async (
  url,
  state,
  publicKey,
  creator,
  setState,
  setContentMenu,
  videoUrls,
  setIsDepositeLoading
) => {
  try {
    console.log("extractVideoFromZip");
    console.log("extractVideoFromZip url = ", url);

    const videoFiles = [];
    const zip = new JSZip();

    const response = await fetch(url);

    console.log("response video = ", response);

    const archive = await zip.loadAsync(response.blob());

    // archive.forEach((relativePath, zipEntry) => {
    //   // if (zipEntry.name.endsWith(".mp4") || zipEntry.name.endsWith(".avi") || zipEntry.name.endsWith(".mov")) {
    //     zipEntry.async("blob").then((blob) => {
    //       videoFiles.push(blob);
    //       console.log("videoFiles = ", videoFiles);
    //     });
    //   // }
    // });

    const videosAll = await videoExtractutil(archive);

    console.log("videosAll = ", videosAll);

    const finalVideos = await Promise.all(videosAll.videos);

    console.log("finalVideos = ", finalVideos);

    if(finalVideos.length !== state.collection.no_of_nfts) {
      return toast.error("The number of videos and the number of nfts are not same")
    }

    const iFormData = new FormData();

    for (let i = 0; i < finalVideos.length; i++) {
      iFormData.append(
        "file",
        finalVideos[i],
        `${state.collection.collection_name}_Assets/${
          videosAll.fileNames[i].split(".")[0]
        }`,
        // `${i}`
      );
      // delete nftImage.tempImg;
    }

    const iResponse = await axios({
      method: "post",
      url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
      data: iFormData,
      headers,
    });

    // if (videoUrls.length > 0) {
    if (iResponse.data) {
      const jFormData = new FormData();
      let jsonDatatemp;
      for (let i = 0; i < finalVideos.length; i++) {
        jsonDatatemp = {};
        jsonDatatemp.image = `ipfs://${iResponse.data.IpfsHash}/${
          videosAll.fileNames[i].split(".")[0]
        }`;
        // jsonDatatemp.video = videoUrls[i];
        jsonDatatemp.name = `${state.collection.collection_name} #${
          i
        }`;
        jFormData.append(
          "file",
          jsontoFile(
            jsonDatatemp,
            `${videosAll.fileNames[i].split(".")[0]}.json`
          ),
          `${state.collection.collection_name}_Metadata/${
            i
          }.json`
        );
      }
      jFormData.append(
        "file",
        jsontoFile(
          {
            name: state.collection.collection_name,
            description: "",
            video: `https://gateway.pinata.cloud/ipfs/${iResponse.data.IpfsHash}/0`,
            external_link: "",
            seller_fee_basis_points: 200,
            // fee_recipient: accountId, // TODO :: Uncomment by passing actual id
          },
          "collection.json"
        ),
        `${state.collection.collection_name}_Metadata/collection`
      );
      const jResponse = await axios({
        method: "post",
        url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
        data: jFormData,
        headers,
      });
      if (jResponse.data) {
        console.log("deploying...");
        // const web3 = new Web3(provider);
        const web3 = new Web3(window.ethereum);

        console.log("web3 = ", web3);
        let deploy_contract = new web3.eth.Contract(Nft.abi);
        console.log("deploy_contract = ", deploy_contract);
        console.log("no_of_nfts_per_user = ", state.no_of_nfts_per_user);
        const params = [
          parseInt(state.collection.no_of_nfts_per_user), //_noOfNFTperuser
          `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}/`, // Uri
          parseInt(state.collection.no_of_nfts), // _totalSupply
          web3.utils.toWei(state.collection.price_per_nft.toString(), "ether"), // _price
          state.collection.collection_name, // name
          state.collection.nft_handle, // symbol
          true,
        ];
        console.log("params", params, publicKey);
        const payload = {
          arguments: params,
          data: Nft.bytecode.toString(),
        };
        let transactionHash;
        await deploy_contract
          .deploy(payload)
          .send({
            from: publicKey,
          })
          .on("transactionHash", async function (hash) {
            console.log("transactionHash", hash);
            transactionHash = hash;
          })
          .on("confirmation", async (index, contractdetail) => {
            console.log("index, contractdetail = ", index, contractdetail);
            if (
              contractdetail &&
              contractdetail.contractAddress &&
              index === 3
            ) {
              const nftContract = new web3.eth.Contract(
                Nft.abi,
                contractdetail.contractAddress
              );
              await nftContract.methods
                .transferOwnership(creator)
                .send({ from: publicKey });
              const ownerCheck2 = await nftContract.methods.owner().call();
              // await AxiosInstance.post("/nftcreator/updateStage", {
              //   _id: state.collection._id,
              //   stage: "Summary",
              //   contract_address: contractdetail.contractAddress
              // });

              console.log("ownerCheck2 = ", ownerCheck2);

              await AxiosInstance.post("/nftcreator/updateStage", {
                _id: state.collection._id,
                stage: "Summary",
                contract_address: contractdetail.contractAddress,
                uri:`https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}`
              });
              setState({
                ...state,
                currentStep: 5,
              })
              setContentMenu(false);

              console.log("ownerCheck2", ownerCheck2);
            }
          })
          .catch((error) => {
            setIsDepositeLoading(false);
            console.log(JSON.parse(error));
            if (error?.data?.message) {
              toast.error(error.data.message);
            } else {
              toast.error(error.message);
            }
          });
      }
    }

    // 0x2712f0a67f5d8c89cf274dd0a4cc9d7f4ab20584e2108220b10754c79385045c
    return finalVideos;
  } catch (err) {
    console.log("err on extract video from zip", err);
  }
};

export const extractAudioFromZip = async (
  url,
  state,
  publicKey,
  creator,
  setState,
  setContentMenu,
  setIsDepositeLoading
) => {
  try {
    const audioFiles = [];
    const zip = new JSZip();

    const response = await fetch(url);

    const archive = await zip.loadAsync(response.blob());

    // archive.forEach((relativePath, zipEntry) => {
    //   if (
    //     zipEntry.name.endsWith(".mp3") ||
    //     zipEntry.name.endsWith(".wav") ||
    //     zipEntry.name.endsWith(".ogg")
    //   ) {
    //     zipEntry.async("blob").then((blob) => {
    //       audioFiles.push(blob);
    //     });
    //   }
    // });

    const audioAll = await audioExtractutil(archive);

    console.log("audioAll = ", audioAll);

    const finalAudios = await Promise.all(audioAll.audios);

    console.log("finalAudios = ", finalAudios);

    console.log("finalAudios.length = ", finalAudios.length, state);

    if(finalAudios.length !== state.collection.no_of_nfts) {
      return toast.error("The number of audios and the number of nfts are not same")
    }console.log("response", audioFiles);

    const iFormData = new FormData();

    for (let i = 0; i < finalAudios.length; i++) {
      iFormData.append(
        "file",
        finalAudios[i],
        `${state.collection.collection_name}_Assets/${
          audioAll.fileNames[i].split(".")[0]
        }`
      );
      // delete nftImage.tempImg;
    }

    const iResponse = await axios({
      method: "post",
      url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
      data: iFormData,
      headers,
    });

    if (iResponse.data) {
      const jFormData = new FormData();
      let jsonDatatemp;
      for (let i = 0; i < finalAudios.length; i++) {
        jsonDatatemp = {};
        jsonDatatemp.image = `ipfs://${iResponse.data.IpfsHash}/${
          audioAll.fileNames[i].split(".")[0]
        }`;
        jsonDatatemp.name = `${state.collection.collection_name} #${
         i
        }`;
        jFormData.append(
          "file",
          jsontoFile(
            jsonDatatemp,
            `${audioAll.fileNames[i].split(".")[0]}.json`
          ),
          `${state.collection.collection_name}_Metadata/${
            i
          }.json`
        );
      }
      jFormData.append(
        "file",
        jsontoFile(
          {
            name: state.collection.collection_name,
            description: "",
            audio: `https://gateway.pinata.cloud/ipfs/${iResponse.data.IpfsHash}/0`,
            external_link: "",
            seller_fee_basis_points: 200,
            // fee_recipient: accountId, // TODO :: Uncomment by passing actual id
          },
          "collection.json"
        ),
        `${state.collection.collection_name}_Metadata/collection`
      );
      const jResponse = await axios({
        method: "post",
        url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
        data: jFormData,
        headers,
      });
      if (jResponse.data) {
        console.log("deploying...");
        // const web3 = new Web3(provider);
        const web3 = new Web3(window.ethereum);

        console.log("web3 = ", web3);
        let deploy_contract = new web3.eth.Contract(Nft.abi);
        console.log("deploy_contract = ", deploy_contract);
        console.log("no_of_nfts_per_user = ", state.no_of_nfts_per_user);
        const params = [
          parseInt(state.collection.no_of_nfts_per_user), //_noOfNFTperuser
          `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}/`, // Uri
          parseInt(state.collection.no_of_nfts), // _totalSupply
          web3.utils.toWei(state.collection.price_per_nft.toString(), "ether"), // _price
          state.collection.collection_name, // name
          state.collection.nft_handle, // symbol
          true,
        ];
        console.log("params", params, publicKey);
        const payload = {
          arguments: params,
          data: Nft.bytecode.toString(),
        };
        let transactionHash;
        await deploy_contract
          .deploy(payload)
          .send({
            from: publicKey,
          })
          .on("transactionHash", async function (hash) {
            console.log("transactionHash", hash);
            transactionHash = hash;
          })
          .on("confirmation", async (index, contractdetail) => {
            console.log("index, contractdetail = ", index, contractdetail);
            if (
              contractdetail &&
              contractdetail.contractAddress &&
              index === 3
            ) {
              const nftContract = new web3.eth.Contract(
                Nft.abi,
                contractdetail.contractAddress
              );
              await nftContract.methods
                .transferOwnership(creator)
                .send({ from: publicKey });
              const ownerCheck2 = await nftContract.methods.owner().call();
              // await AxiosInstance.post("/nftcreator/updateStage", {
              //   _id: state.collection._id,
              //   stage: "Summary",
              //   contract_address: contractdetail.contractAddress
              // });

              console.log("ownerCheck2 = ", ownerCheck2);

              await AxiosInstance.post("/nftcreator/updateStage", {
                _id: state.collection._id,
                stage: "Summary",
                contract_address: contractdetail.contractAddress,
                uri:`https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}`
              });
              setState({
                ...state,
                currentStep: 5,
              })
              setContentMenu(false);
              console.log("ownerCheck2", ownerCheck2);
            }
          })
          .catch((error) => {
            setIsDepositeLoading(false);
            console.log(JSON.parse(error));
            if (error?.data?.message) {
              toast.error(error.data.message);
            } else {
              toast.error(error.message);
            }
          });
      }
    }
  } catch (err) {
    console.log("err on extract audio from zip", err);
  }
};

export const extractDocumentFromZip = async (
  url,
  state,
  publicKey,
  creator,
  setState,
  setContentMenu,
  setIsDepositeLoading
) => {
  try {
    // const audioFiles = [];
    const zip = new JSZip();

    console.log("url = ", url);

    const response = await fetch(url);

    console.log("reponse = ", response);

    const archive = await zip.loadAsync(response.blob());

    // archive.forEach((relativePath, zipEntry) => {
    //   if (
    //     zipEntry.name.endsWith(".mp3") ||
    //     zipEntry.name.endsWith(".wav") ||
    //     zipEntry.name.endsWith(".ogg")
    //   ) {
    //     zipEntry.async("blob").then((blob) => {
    //       audioFiles.push(blob);
    //     });
    //   }
    // });

    const documentAll = await documentExtractutil(archive);

    console.log("documentAll = ", documentAll);

    const finalDocuments = await Promise.all(documentAll.documents);

    console.log("finalDocuments = ", finalDocuments);

    if(finalDocuments.length !== state.collection.no_of_nfts) {
      return toast.error("The number of documents and the number of nfts are not same")
    }// console.log("response", audioFiles);

    const iFormData = new FormData();

    for (let i = 0; i < finalDocuments.length; i++) {
      iFormData.append(
        "file",
        finalDocuments[i],
        `${state.collection.collection_name}_Assets/${
          documentAll.fileNames[i].split(".")[0]
        }`
      );
      // delete nftImage.tempImg;
    }

    const iResponse = await axios({
      method: "post",
      url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
      data: iFormData,
      headers,
    });

    if (iResponse.data) {
      const jFormData = new FormData();
      let jsonDatatemp;
      for (let i = 0; i < finalDocuments.length; i++) {
        jsonDatatemp = {};
        jsonDatatemp.image = `ipfs://${iResponse.data.IpfsHash}/${
          documentAll.fileNames[i].split(".")[0]
        }`;
        jsonDatatemp.name = `${state.collection.collection_name} #${
          i
        }`;
        jFormData.append(
          "file",
          jsontoFile(
            jsonDatatemp,
            `${documentAll.fileNames[i].split(".")[0]}.json`
          ),
          `${state.collection.collection_name}_Metadata/${
            i
          }.json`
        );
      }
      jFormData.append(
        "file",
        jsontoFile(
          {
            name: state.collection.collection_name,
            description: "",
            document: `https://gateway.pinata.cloud/ipfs/${iResponse.data.IpfsHash}/0`,
            external_link: "",
            seller_fee_basis_points: 200,
            // fee_recipient: accountId, // TODO :: Uncomment by passing actual id
          },
          "collection.json"
        ),
        `${state.collection.collection_name}_Metadata/collection`
      );
      const jResponse = await axios({
        method: "post",
        url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
        data: jFormData,
        headers,
      });
      if (jResponse.data) {
        console.log("deploying...");
        // const web3 = new Web3(provider);
        const web3 = new Web3(window.ethereum);

        console.log("web3 = ", web3);
        let deploy_contract = new web3.eth.Contract(Nft.abi);
        console.log("deploy_contract = ", deploy_contract);
        console.log("no_of_nfts_per_user = ", state.no_of_nfts_per_user);
        const params = [
          parseInt(state.collection.no_of_nfts_per_user), //_noOfNFTperuser
          `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}/`, // Uri
          parseInt(state.collection.no_of_nfts), // _totalSupply
          web3.utils.toWei(state.collection.price_per_nft.toString(), "ether"), // _price
          state.collection.collection_name, // name
          state.collection.nft_handle, // symbol
          true,
        ];
        console.log("params", params, publicKey);
        const payload = {
          arguments: params,
          data: Nft.bytecode.toString(),
        };
        let transactionHash;
        await deploy_contract
          .deploy(payload)
          .send({
            from: publicKey,
          })
          .on("transactionHash", async function (hash) {
            console.log("transactionHash", hash);
            transactionHash = hash;
          })
          .on("confirmation", async (index, contractdetail) => {
            console.log("index, contractdetail = ", index, contractdetail);
            if (
              contractdetail &&
              contractdetail.contractAddress &&
              index === 3
            ) {
              const nftContract = new web3.eth.Contract(
                Nft.abi,
                contractdetail.contractAddress
              );
              await nftContract.methods
                .transferOwnership(creator)
                .send({ from: publicKey });
              const ownerCheck2 = await nftContract.methods.owner().call();
              // await AxiosInstance.post("/nftcreator/updateStage", {
              //   _id: state.collection._id,
              //   stage: "Summary",
              //   contract_address: contractdetail.contractAddress
              // });

              console.log("ownerCheck2 = ", ownerCheck2);

              await AxiosInstance.post("/nftcreator/updateStage", {
                _id: state.collection._id,
                stage: "Summary",
                contract_address: contractdetail.contractAddress,
                uri:`https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}`
              });
              setState({
                ...state,
                currentStep: 5,
              })
              setContentMenu(false);
              console.log("ownerCheck2", ownerCheck2);
            }
          })
          .catch((error) => {
            setIsDepositeLoading(false);
            console.log(JSON.parse(error));
            if (error?.data?.message) {
              toast.error(error.data.message);
            } else {
              toast.error(error.message);
            }
          });
      }
    }
  } catch (err) {
    console.log("err on extract document from zip", err);
  }
};